import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Alert
} from 'reactstrap';
import { IoMdClose } from 'react-icons/io';

import {
  updateAllCategories,
  updateCategoryName,
  updateProductCategory,
  addNewSubcategory,
  updateSubcategoryName,
  updateProductSubcategory
} from '../../firestore/categories';
import { updateAllProducts } from '../../firestore/products';
import { autoCapitalize } from '../../firestore/kebab-case';
import { UploadToStorage } from '../../firestore/storage';
import SubCategDropdown from './SubCategDropdown';

const ModifyCategoryModal = ({
  visible, setVisible, categ, categId, subcategs, setSubcategs, img, index, setCategories, categories
}) => {
  const initialProducts = () => JSON.parse(window.localStorage.getItem('allProducts')) || [];
  const [selectedOption, setSelectedOption] = useState('');
  const [category, setCategory] = useState(categ);
  const [subcategories, setSubcategories] = useState(subcategs);
  const [image, setImage] = useState(img);
  const [fileName, setfileName] = useState([]);
  const [allProducts, setAllProducts] = useState(initialProducts);
  const [confirmModal, setConfirmModal] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [showSubcategAlert, setShowSubcategAlert] = useState(false);

  const toggleSubcategAlert = () => setShowSubcategAlert(false);

  useEffect(() => {
    setSubcategories(subcategs);
  }, [subcategs]);

  useEffect(() => {
    window.localStorage.setItem('allProducts', JSON.stringify(allProducts));
  }, [allProducts]);

  const handleCategory = (e) => setCategory(e.target.value);

  const toggleConfirmModal = () => setConfirmModal(!confirmModal);

  const toggleDiscardChanges = () => setDiscardChanges(!discardChanges);

  function handleFileInputChange(event) {
    setImage(URL.createObjectURL(event.target.files[0]));
    setfileName(event.target.files[0]);
  }

  const toggleModal = () => {
    // restore initial values
    toggleDiscardChanges();
    setCategory(categ);
    setSubcategories(subcategs);
    setImage(img);
    setVisible(!visible);
  };

  const saveChanges = async () => {
    if (categories[index].name.toUpperCase() !== category.toUpperCase()) {
      updateCategory();
    }
    updateSubcategory();

    toggleConfirmModal();
    toggleModal();
  };

  const updateCategory = async () => {
    const tmp = [...categories];
    tmp[index].name = autoCapitalize(category);
    updateCategoryName(categories[index].id, category, '');

    const prodsAux = [...allProducts];
    prodsAux.forEach(async (item, i) => {
      if (item.categ.id === categories[index].id) {
        prodsAux[i] = { ...item, name: autoCapitalize(category) };
        await updateProductCategory(item.categ.id, item.subcateg.id, item.id, category);
      }
    });

    updateAllCategories(tmp);
    updateAllProducts(prodsAux);
    setAllProducts(prodsAux);

    setCategories(tmp);
  };

  const updateSubcategory = async () => {
    // subcategs is the old array
    // subcategories is the modified array
    const oldArray = [...subcategs];
    const newArray = [...subcategories];
    const tmpCategs = [...categories];
    const prodsAux = [...allProducts];

    async function updateImage() {
      if (image !== img) {
        const url = await UploadToStorage(`companies/companyName/category/${category.name}`, fileName);
        const newImage = url;
        tmpCategs[index].image = newImage;
      }
    }
    Promise.all(newArray.map(async (item, subcategIndex) => {
      let i;
      if (item.id === '') {
        i = oldArray.findIndex((subcat) => item.name === subcat.name);
        if (i !== -1) {
          newArray[subcategIndex] = { ...item, id: oldArray[i].id };
        } else {
          // create subcateg id
          const docId = await addNewSubcategory(categId, item.name);
          newArray[subcategIndex] = { ...item, id: docId };
        }
      } else {
        i = oldArray.findIndex((subcateg) => subcateg.id === item.id);
        if (i === -1) {
          // delete subcategory
          prodsAux.map(async (prod) => {
            if (prod.subcateg.id === item.id) {
              return false;
            }
            await updateProductSubcategory(categId, item.id, prod.id, item.name);
            return true;
          });
          return false;
        } if (item.name !== oldArray[i].name) {
          // update to new name
          prodsAux.forEach(async (prod, prodIndex) => {
            if (prod.subcateg.id === item.id) {
              prodsAux[prodIndex] = { ...prod, subcateg: { ...prod.subcateg, name: item.name } };
              await updateProductSubcategory(categId, item.id, prod.id, item.name);
            }
          });
          updateSubcategoryName(categId, item.id, item.name);
        }
      }
    }))
      .then(async () => {
        await updateImage();
        tmpCategs[index].subcateg = newArray;
        updateAllCategories(tmpCategs);
        updateAllProducts(prodsAux);
        setCategories(tmpCategs);
        setSubcategs(newArray);
        setAllProducts(prodsAux);
      });
  };

  return (
    <Modal isOpen={visible} toggle={toggleDiscardChanges} centered>
      <Alert color="danger" isOpen={showSubcategAlert} toggle={toggleSubcategAlert}>
        No se puede eliminar esta subcategoria.
        Cada categoria debe de tener al menos un subcategoria.
      </Alert>
      <ModalBody>
        <div className="container">
          <div className="row d-flex justify-content-end">
            <button type="button" className="bg-white" onClick={toggleDiscardChanges}>
              <IoMdClose size={20} />
            </button>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-10 col-md-5 bg-white d-flex flex-column align-content-center justify-content-center m-3">
              <label className="d-flex flex-column w-100 h-100 justify-content-center align-self-center">
                <img className="img-fluid" alt="" src={image} />
                <span className="d-flex justify-content-center align-self-center text-center py-2">
                  Cambiar imagen
                </span>
                <input className="d-none" type="file" onChange={handleFileInputChange} />
              </label>
            </div>
          </div>

          <div className="row">
            <form className="col-12">
              <span><b>Nombre de la categoría</b></span>
              <input type="text" id="exampleForm2" className="form-control" value={category} onChange={handleCategory} />
              <SubCategDropdown
                title="Subcategorías"
                options={subcategories}
                setSelectedOption={setSelectedOption}
                selectedOption={selectedOption}
                setOptions={setSubcategories}
                deleteAlert={setShowSubcategAlert}
              />
            </form>
          </div>

          <div className="row d-flex justify-content-center mt-5">
            <button type="button" onClick={toggleConfirmModal} className="border border-dark btn btn-danger bg-red px-3 py-2">
              Guardar Cambios
            </button>
          </div>
        </div>
      </ModalBody>
      <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
        <ModalHeader toggle={toggleConfirmModal}>
          Confirmar cambios
        </ModalHeader>
        <ModalBody className="mb-4">
          ¿Estás seguro de que quieres guardar los cambios? <br /> <br />
          Si has borrado una subcategoría, <strong>todos los productos</strong> de la misma
          serán también <strong>eliminados</strong>.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => saveChanges()}>Confirmar</Button>
          <Button color="secondary" onClick={toggleConfirmModal}>Cancelar</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={discardChanges} toggle={toggleDiscardChanges}>
        <ModalHeader toggle={toggleDiscardChanges}>
          Descartar cambios
        </ModalHeader>
        <ModalBody className="mb-4">
          ¿Estás seguro de que quieres descartar los cambios los cambios?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>Confirmar</Button>
          <Button color="secondary" onClick={toggleDiscardChanges}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </Modal>
  );
};

export default ModifyCategoryModal;
