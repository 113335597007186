import { auth } from './config';

export const signIn = async (email, password, history, setAlert, setError) => {
  auth.signInWithEmailAndPassword(email, password)
    .then(() => {
      history.push('/categories');
    })
    .catch((error) => {
      const errorMsg = error.message;
      setError(errorMsg);
      setAlert(true);
    });
};

export const signOut = (history) => {
  auth.signOut().then(() => {
    history.push('/');
  }).catch((error) => {
    console.log(error);
  });
};

export default auth;
