import React, { useState, useEffect } from 'react';

import './styles/categListItem.css';
import { TiPencil } from 'react-icons/ti';
import { RiDeleteBinLine } from 'react-icons/ri';

import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import ModifyCategoryModal from './ModifyCategoryModal';
import { updateAllCategories } from '../../firestore/categories';
import { updateAllProducts } from '../../firestore/products';

const CategListItem = ({
  index, image, name, categId, subCategories, setSubcategs, setCategories, categories
}) => {
  const initialProducts = () => JSON.parse(window.localStorage.getItem('allProducts')) || [];
  const [showModal, setShowModal] = useState(false);
  const [numSubcategs, setnumSubcategs] = useState(0);
  const [eliminateModal, setEliminateModal] = useState(false);
  const [allProducts, setAllProducts] = useState(initialProducts);

  const toggleEliminate = () => setEliminateModal(!eliminateModal);

  useEffect(() => {
    window.localStorage.setItem('allProducts', JSON.stringify(allProducts));
  }, [allProducts]);

  useEffect(() => {
    if (subCategories !== undefined) setnumSubcategs(subCategories.length);
  }, [subCategories]);

  const eliminateCategory = () => {
    const tmpCategs = [...categories];
    tmpCategs.splice(index, 1);
    setCategories(tmpCategs);
    updateAllCategories(tmpCategs);
    const tmpProds = allProducts.filter((item) => {
      if (item.categ.id === categories[index].id) {
        return false;
      }
      return true;
    });
    setAllProducts(tmpProds);
    updateAllProducts(tmpProds);
    toggleEliminate();
  };

  const eliminateCategoryModal = () => (
    <Modal isOpen={eliminateModal} toggle={toggleEliminate}>
      <ModalHeader toggle={toggleEliminate}>
        Eliminar Categoría
      </ModalHeader>
      <ModalBody>
        ¿Estás seguro de que quieres eliminar toda la categoría <strong>{name}</strong>? <br />
        <strong> Todos los productos</strong> asociados con {name} y sus subcategorías serán
        <strong> eliminados</strong>.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={eliminateCategory}>Confimar</Button>
        <Button color="secondary" onClick={toggleEliminate}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <li className="col-12 col-lg-6 my-1">
      <ModifyCategoryModal
        visible={showModal}
        setVisible={setShowModal}
        categ={name}
        categId={categId}
        subcategs={subCategories}
        setSubcategs={setSubcategs}
        img={image}
        index={index}
        setCategories={setCategories}
        categories={categories}
      />

      {eliminateCategoryModal()}

      <div className="row d-flex align-content-center bg-white mx-1 h-100 justify-content-center">
        <div className="col-8 col-sm-6 col-md-4 d-flex flex-column justify-content-center align-items-center pt-5 py-sm-3">
          <img className="img-fluid" src={image} alt="product" />
        </div>

        <div className="col-12 col-sm-6 col-md-4 d-flex justify-content-center justify-content-sm-start align-items-center py-3 pl-lg-0">
          <div className="d-flex flex-column">
            <div className="p-1">Categoría: {name}</div>
            <div className="p-1">Subcategorías: {numSubcategs}</div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-4 d-flex flex-column justify-content-center py-3 pl-lg-0">
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="d-flex align-content-center justify-content-center border border-dark py-1 btn shadow-none"
            style={{ margin: 5 }}
          >
            <TiPencil size={20} />
            <span className="pl-2">Modificar</span>
          </button>
          <button
            type="button"
            className="d-flex align-content-center justify-content-center border border-dark py-1 btn shadow-none"
            style={{ margin: 5 }}
            onClick={toggleEliminate}
          >
            <RiDeleteBinLine size={20} />
            <span className="pl-2">Eliminar</span>
          </button>
        </div>
      </div>
    </li>
  );
};

export default CategListItem;
