import React, { useState, useEffect } from 'react';
import {
  ButtonDropdown, DropdownMenu, DropdownToggle, Collapse
} from 'reactstrap';
import { AiOutlineSortAscending, AiOutlineFileAdd } from 'react-icons/ai';
import {
  FiFilter, FiSearch, FiChevronDown, FiChevronUp
} from 'react-icons/fi';
import { GrAdd } from 'react-icons/gr';
import { RiEraserLine } from 'react-icons/ri';
import { parse } from 'papaparse';

import { IoMdClose } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';
import ProductListItem from '../../components/products/ProductListItem';

import {
  getAllProducts
} from '../../firestore/products';
import {
  getAllCategories
} from '../../firestore/categories';

function ProductsScreen() {
  const initialProducts = () => JSON.parse(window.localStorage.getItem('allProducts')) || [];
  const initalCategories = () => JSON.parse(window.localStorage.getItem('allCategories')) || [];
  const [products, setProducts] = useState(initialProducts);
  const [categories, setCategories] = useState(initalCategories);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const [showCategories, setShowCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  const [modal, setModal] = useState(false);
  const history = useHistory();

  const toggleCategories = () => setShowCategories(!showCategories);

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const toggleModal = () => setModal(!modal);

  useEffect(() => {
    getProducts();
    getCategories();
  }, []);

  // Filter Products by category and subcategory
  useEffect(() => {
    let tmp = [];
    if (selectedCategory === '') {
      tmp = [...products];
    } else if (selectedCategory !== '' && selectedSubcategory === `${selectedCategory}all`) {
      tmp = products.filter((item) => (item.categ.id === selectedCategory));
      toggleCategories();
    } else {
      tmp = products.filter((item) => {
        if (item.categ.id === selectedCategory && item.subcateg.id === selectedSubcategory) {
          return true;
        }
        return false;
      });
      toggleCategories();
    }
    setFilteredProducts(tmp);
  }, [selectedSubcategory]);

  useEffect(() => {
    window.localStorage.setItem('allProducts', JSON.stringify(products));
  }, [products]);

  useEffect(() => {
    window.localStorage.setItem('allCategories', JSON.stringify(categories));
  }, [categories]);

  const getProducts = async () => {
    const productsAux = await getAllProducts();
    setProducts(productsAux);
  };

  async function getCategories() {
    const categsAux = await getAllCategories();
    setCategories(categsAux);
  }

  function items() {
    return (filteredProducts.map((item) => {
      const tmpName = item.name.toLowerCase();
      const tmpBarCode = item.barCode.toLowerCase();
      if (tmpName.includes(searchInput.toLowerCase())
      || tmpBarCode.includes(searchInput.toLowerCase())) {
        return (
          <ProductListItem
            product={item}
            key={item.id}
          />
        );
      }
      return (<div />);
    }));
  }

  const checkOpen = (categ) => categ === selectedCategory;

  const selectCategory = (categ) => {
    if (categ === selectedCategory) {
      setSelectedCategory('');
    } else {
      setSelectedCategory(categ);
    }
  };

  const eraseFilters = () => {
    setSelectedCategory('');
    setSelectedSubcategory('');
  };

  const categoriesDropdown = () => (
    categories.map((item) => (
      <div key={item.id}>
        <button type="button" className="btn bg-trasnparent border-0 shadow-none w-100 text-left pb-0" onClick={() => selectCategory(item.id)}>
          <div className="d-flex row align-items-center px-3 justify-content-between">
            <span className="d-flex align-self-center"> {item.name}</span>
            {checkOpen(item.id) ? <FiChevronUp /> : <FiChevronDown />}
          </div>
          <div className="dropdown-divider" />
        </button>

        <Collapse isOpen={checkOpen(item.id)} className="px-3">
          <ul>
            {subcategoriesDropdown(item.subcateg)}
            <li>
              <button type="button" className="btn bg-trasnparent border-0 shadow-none w-100 text-left pb-0" onClick={() => setSelectedSubcategory(`${item.id}all`)}>
                {selectedSubcategory === `${item.id}all`
                  ? <strong>Ver Todo</strong> : <span>Ver Todo</span> }
              </button>
            </li>
          </ul>
        </Collapse>
      </div>
    ))
  );

  const subcategoriesDropdown = (subcategories) => (
    subcategories.map((item) => (
      <li key={item.id}>
        <button type="button" className="btn bg-trasnparent border-0 shadow-none w-100 text-left pb-0" onClick={() => setSelectedSubcategory(item.id)}>
          {item.id === selectedSubcategory
            ? <strong>{item.name}</strong> : <span>{item.name}</span> }
        </button>
      </li>
    ))
  );

  const handleFile = (event) => {
    parse(event.target.files[0], {
      header: true,
      complete(results) {
        // since the last element read from the csv is empty
        results.data.pop();
        history.push({ pathname: '/products/addCSV', data: results.data });
      }
    });
    toggleModal();
  };

  return (
    <div className="d-flex container-fluid flex-column">
      <Title title="PRODUCTOS" />
      <div className="container-fluid d-flex p-0 h-100">
        <SideNavBar />
        <div className="container">
          <div className="row d-flex align-content-center justify-content-sm-around justify-content-md-between justify-content-center mt-2 mt-md-3">
            <div className="col-12 col-md-4 d-flex flex-row d-flex justify-content-center my-2">
              <div className="btn btn-group">
                <ButtonDropdown isOpen={showCategories} toggle={toggleCategories}>
                  <button className="px-3 py-2 border border-dark d-flex justify-content-center align-items-center" type="button" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                    <AiOutlineSortAscending size={18} />
                    <span style={{ paddingLeft: 10 }}>Ordernar</span>
                  </button>
                  <DropdownToggle className="px-3 py-2 border border-dark d-flex justify-content-center align-items-center bg-white text-dark rounded-right shadow-none">
                    <FiFilter size={18} />
                    <span style={{ paddingLeft: 10 }}>Filtrar</span>
                  </DropdownToggle>
                  <DropdownMenu className="w-100 p-0" right style={{ outline: 'none' }}>
                    <div className="d-flex flex-row align-items-center w-100 justify-content-between">
                      <button type="button" className="btn bg-transparent shadow-none px-3" onClick={() => eraseFilters()}>
                        <RiEraserLine size={14} />
                        <span className="pl-2" style={{ fontSize: 12 }}>
                          Borrar Filtros
                        </span>
                      </button>
                      <button type="button" className="d-flex btn bg-transparent shadow-none justify-content-end" onClick={toggleCategories}>
                        <IoMdClose size={20} />
                      </button>
                    </div>
                    <h6 className="font-weight-bold text-dark text-center my-2">Categorias</h6>
                    {categoriesDropdown()}
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </div>
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 my-2">
              <div className="d-flex border align-items-center mx-3 mx-md-0">
                <FiSearch size={14} />
                <input className="container border-0 no-outline py-2" name="search" placeholder="Buscar producto..." type="search" value={searchInput} onChange={handleSearch} />
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-10 col-sm-6 col-md-4 bg-white py-1 my-3 mx-2 border border-dark">
              <a className="d-flex align-items-center justify-content-center text-decoration-none text-reset m-0" href="/products/addProduct">
                <GrAdd size={20} />
                <span className="px-2 py-1">
                  Crear nuevo producto
                </span>
              </a>
            </div>
            <div className="col-10 col-sm-6 col-md-4 bg-white py-1 my-3 mx-2 border border-dark">
              <label className="d-flex align-items-center justify-content-center text-decoration-none text-reset m-0" href="/">
                <AiOutlineFileAdd size={20} />
                <span className="px-2 py-1">
                  Subir productos por Excel
                </span>
                <input id="csv" className="d-none" type="file" onChange={handleFile} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
              </label>
            </div>
          </div>
          <div className="container-fluid">
            <ul className="container">
              {products === [] ? <div /> : items()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsScreen;
