function conversion(input) {
  return input.replace(/\s+/g, '-').toLowerCase();
}

export const autoCapitalize = (input) => {
  if (input !== '') {
    return input[0].toUpperCase() + input.slice(1).toLowerCase();
  }
};
// export const toKebab = (input) {}

export default conversion;
