import React from 'react';
import { signIn } from '../../firestore/authentication';

const btn = ({
  text, action, email, password, history, isDisable, setAlert, setError
}) => {
  function doAction() {
    switch (action) {
      case 'signin':
        signIn(email, password, history, setAlert, setError);
        break;
      default:
        break;
    }
  }

  const buttonActive = 'd-flex bg-red py-1 px-5 justify-content-center text-white align-items-center border';
  const buttonInactive = 'd-flex bg-grey py-1 px-5 justify-content-center text-white align-items-center border';

  return (
    <button type="button" onClick={doAction} disabled={isDisable} className={isDisable ? buttonInactive : buttonActive}>
      <span className="px-2">{text}</span>
    </button>
  );
};

export default btn;
