import React, { useState } from 'react';

import { AiOutlineBook, AiOutlineShop } from 'react-icons/ai';
import { MdFormatListBulleted } from 'react-icons/md';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { FiUser } from 'react-icons/fi';
// import { GoGraph } from 'react-icons/go';

import {
  Navbar,
  Collapse,
  Nav,
} from 'reactstrap';

import './styles/sideNavBar.css';
import { useLocation } from 'react-router-dom';

function SideNavBar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation();

  const categoriesActivate = (location.pathname.includes('/categories') ? ' element text-danger' : 'element');
  const productsActivate = (location.pathname.includes('/products') ? ' element text-danger' : 'element');
  const ordersActivate = (location.pathname.includes('/orders') ? ' element text-danger' : 'element');
  const profileActivate = (location.pathname.includes('/profile') ? ' element text-danger' : 'element');
  // const statisticsActivate = (location.pathname.includes('/statistics')
  // ? ' element text-danger' : 'element');

  return (
    <Navbar light color="white" className="d-none d-sm-flex align-content-start h-100">
      <div className="d-flex flex-column justify-content-center">
        {!isOpen
          ? (
            <div className="element">
              <IoMdMenu size={25} onClick={toggle} />
            </div>
          )
          : (
            <div className="element align-self-end">
              <IoMdClose size={25} onClick={toggle} />
            </div>
          )}
        <div className="d-flex flex-row justify-content-center">
          <div className="d-flex flex-column justify-content-center">
            <Nav vertical className="align-top">
              {/* <li>
                <a href="/statistics" className="element">
                  <GoGraph size={25} />
                </a>
              </li> */}
              <li>
                <a href="/categories" className={categoriesActivate}>
                  <MdFormatListBulleted size={25} />
                </a>
              </li>
              <li>
                <a href="/products" className={productsActivate}>
                  <AiOutlineShop size={25} />
                </a>
              </li>
              <li>
                <a href="/orders" className={ordersActivate}>
                  <AiOutlineBook size={25} />
                </a>
              </li>
              <li>
                <a href="/profile" className={profileActivate}>
                  <FiUser size={25} />
                </a>
              </li>
            </Nav>
          </div>
          <div>
            <Collapse isOpen={isOpen} navbar>
              <Nav vertical className="align-top">
                {/* <li>
                  <a href="/statistics" className="element">
                    Estadísticas
                  </a>
                </li> */}
                <li>
                  <a href="/categories" className={categoriesActivate}>
                    Categorías
                  </a>
                </li>
                <li>
                  <a href="/products" className={productsActivate}>
                    Productos
                  </a>
                </li>
                <li>
                  <a href="/orders" className={ordersActivate}>
                    Pedidos
                  </a>
                </li>
                <li>
                  <a href="/profile" className={profileActivate}>
                    Perfil
                  </a>
                </li>
              </Nav>

            </Collapse>
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default SideNavBar;
