import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Alert
} from 'reactstrap';

import { useParams, useHistory } from 'react-router-dom';
import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';
import { showOptions } from '../../helpers/products';
import {
  getProductOptions, updateOptions, updateAllProducts
} from '../../firestore/products';

const DeleteProductScreen = () => {
  const { ref } = useParams();
  const initialProducts = () => JSON.parse(window.localStorage.getItem('allProducts')) || [];
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState({});
  const [subCategory, setSubcategory] = useState({});
  const [barCode, setBarCode] = useState('');
  const [details, setDetails] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionsId, setOptionsId] = useState('');
  const [allProducts, setAllProducts] = useState(initialProducts);

  const [showDeleteProduct, setShowDeleteProduct] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getProductInfo();
  }, []);

  useEffect(() => {
    window.localStorage.setItem('allProducts', JSON.stringify(allProducts));
  }, [allProducts]);

  const toggleDeleteProduct = () => setShowDeleteProduct(!showDeleteProduct);
  const toggleAlert = () => setShowAlert(!showAlert);

  async function getOptions(categ, subcateg, id) {
    const opts = await getProductOptions(categ, subcateg, id);
    if (opts.options !== undefined) {
      const tmp = [];
      opts.options.forEach((item) => {
        item.prices.forEach((opt) => {
          tmp.push({
            type: item.type,
            size: opt.size,
            price: opt.price,
            image: opt.image
          });
        });
      });
      setOptions(tmp);
      setOptionsId(opts.id);
    }
  }

  async function getProductInfo() {
    const index = allProducts.findIndex((item) => item.id === ref);

    setCategory(allProducts[index].categ);
    setSubcategory(allProducts[index].subcateg);
    setName(allProducts[index].name);
    setBrand(allProducts[index].brand);
    setBarCode(allProducts[index].barCode);
    setDetails(allProducts[index].details);
    setProductImages(allProducts[index].images);

    getOptions(allProducts[index].categ.id, allProducts[index].subcateg.id, ref);
  }

  const deleteOption = (sizeD, imageD, typeD, priceD) => {
    if (options.length > 1) {
      const tmpOptions = options.filter((item) => {
        const {
          type, price, image, size
        } = item;
        if (type === typeD && price === priceD && size === sizeD && image === imageD) {
          return false;
        }
        return item;
      });
      const { unique, lowestPrice } = mergeOptions(tmpOptions);
      const tmpProducts = [...allProducts];
      const index = tmpProducts.findIndex((item) => item.id === ref);
      tmpProducts[index].price = lowestPrice;
      updateAllProducts(tmpProducts);
      setAllProducts(tmpProducts);
      updateOptions(category.id, subCategory.id, ref, optionsId, unique);
      setOptions(tmpOptions);
    } else {
      toggleAlert();
    }
  };

  const mergeOptions = (val) => {
    const unique = [];
    let lowestPrice = val[0].price;
    val.forEach((current) => {
      const x = unique.findIndex((item) => item.type === current.type);

      if (lowestPrice > current.price) lowestPrice = current.price;

      if (x === -1) {
        unique.push({
          type: current.type,
          prices: [{
            price: current.price,
            image: current.image,
            size: current.size,
          }]
        });
      } else {
        unique[x].prices.push({
          price: current.price,
          image: current.image,
          size: current.size,
        });
      }
    });

    setOptions(unique);
    return { unique, lowestPrice };
  };

  const deleteAllProduct = async () => {
    const tmp = allProducts.filter((item) => {
      if (item.id === ref) {
        return false;
      }
      return true;
    });

    await updateAllProducts(tmp);
    setAllProducts(tmp);
    history.goBack();
  };

  const deleteProductModal = () => (
    <Modal isOpen={showDeleteProduct} toggle={toggleDeleteProduct}>
      <ModalHeader toggle={toggleDeleteProduct}>Eliminar Producto</ModalHeader>
      <ModalBody>
        ¿Está seguro de que quiere eliminar todo el producto?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={deleteAllProduct}>Confimar</Button>{' '}
        <Button color="secondary" onClick={toggleDeleteProduct}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );

  const indicators = productImages.map((item, index) => {
    let tmp = '';
    if (index === 0) {
      tmp = 'd-flex my-2 mx-1 previewImg p-0 border border-dark align-items-center';
    } else {
      tmp = 'd-flex my-2 mx-1 previewImg p-0 border align-items-center';
    }
    return (
      <li data-slide-to={index} className="d-flex col-2 col-md-4 p-0 w-100 align-items-center">
        <div className={tmp}>
          <img src={item} alt={item.name} className="img-fluid rounded" />
        </div>
      </li>
    );
  });

  return (
    <div className="d-flex container-fluid flex-column">
      <Title title="ELIMINAR PRODUCTO" buttonText="Eliminar todo" buttonAction={toggleDeleteProduct} />
      <div className="d-flex container-fluid p-0 h-100">
        <SideNavBar />
        {deleteProductModal()}

        <div className="container p-3 mt-4">
          <Alert color="danger" isOpen={showAlert} toggle={toggleAlert}>
            No se puede eliminar esta opción. Cada producto debe tener al menos una opción.
          </Alert>
          <div className="row m-3">
            <span className="row col-12">
              1. Información general del producto
            </span>
            <div className="col-12 col-md-3 d-flex align-items-center mt-3 p-0">
              <div className="d-flex w-100 justify-content-center align-self-center">
                { productImages[0] !== ''
                  ? (
                    <div className="d-flex flex-column">
                      <img className="img-fluid border border-dark" alt="" src={productImages[0]} />
                      <ul className="row mx-3 mx-md-0 my-2">
                        {indicators}
                      </ul>

                    </div>
                  )
                  : (
                    <span className="d-flex justify-content-center align-items-center text-center py-2 border border-dark rounded-0" style={{ height: 150 }}>
                      Insertar imágenes
                    </span>
                  )}
              </div>
            </div>
            <div className="col-12 col-md-9">
              <ul className="row my-4">
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Nombre del producto</b>
                    <input disabled value={name} id="name" className="py-2 px-2 border border-secondary my-2 rounded-0 bg-light" />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Marca del Producto</b>
                    <input disabled value={brand} id="brand" className="py-2 px-2 border border-secondary my-2 rounded-0 bg-light" />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Marca del Producto</b>
                    <span className="py-2 px-2 border border-secondary my-2 rounded-0 bg-light">
                      {category.name}
                    </span>
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Marca del Producto</b>
                    <span className="py-2 px-2 border border-secondary my-2 rounded-0 bg-light">
                      {subCategory.name}
                    </span>
                  </div>
                </li>
                <li className="col-12 col-sm-6 ">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Código de barras</b>
                    <input disabled value={barCode} id="reference" className="py-2 px-2 border border-secondary my-2 rounded-0 bg-light" />
                  </div>
                </li>
                <li className="col-12 col-sm-6 ">
                  <div className="d-flex flex-column mx-lg-3">
                    <span className="text-secondary"> <b className="text-dark">Detalles del producto</b> (Opcional)</span>
                    <textarea value={details} disabled id="reference" rows="3" className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row m-3">
            <span className="row col-12">
              2. Opciones de tamaño y color
            </span>
            <ul className="row container">
              {options === undefined ? <div /> : showOptions(options, setOptions, 'true', deleteOption, toggleAlert)}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProductScreen;
