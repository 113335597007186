import db from './config';

export const getAllProducts = async () => {
  let prods = [];
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('OPT')
    .doc('allProducts')
    .get()
    .then((doc) => {
      try {
        const { products } = doc.data();
        prods = products;
      } catch (error) {
        prods = [];
      }
    });
  return prods;
};

export const addNewProduct = async (categId, subcategId, product) => {
  console.log('vop', product, subcategId);
  let docId = '';
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .collection('PRODUCTS')
    .add(product)
    .then((docRef) => {
      docId = docRef.id;
    });

  return docId;
};

export const updateProductInfo = async (categId, subcategId, productId, product) => {
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .collection('PRODUCTS')
    .doc(productId)
    .set(product);
};

export const getProductOptions = async (categId, subcategId, docId) => {
  let options = {};
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .collection('PRODUCTS')
    .doc(docId)
    .collection('OPTIONS')
    .get()
    .then((optQuery) => {
      optQuery.forEach((optDoc) => {
        const { types } = optDoc.data();
        options = {
          options: types,
          id: optDoc.id
        };
      });
    });

  return options;
};

export const addOptions = async (categId, subcategId, productId, options) => {
  console.log('options', categId, subcategId, options);
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .collection('PRODUCTS')
    .doc(productId)
    .collection('OPTIONS')
    .add({
      types: options
    });
};

export const updateOptions = async (categId, subcategId, productId, optionsId, options) => {
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .collection('PRODUCTS')
    .doc(productId)
    .collection('OPTIONS')
    .doc(optionsId)
    .update({
      types: options
    })
    .catch((error) => {
      // The document probably doesn't exist.
      console.error('Error updating document: ', error);
    });
};

export const updateAllProducts = async (products) => {
  console.log('pp', products);
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('OPT')
    .doc('allProducts')
    .set({
      products
    })
    .catch((error) => {
      // The document probably doesn't exist.
      console.error('Error updating document: ', error);
    });
};

export default db;
