import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { auth } from '../firestore/config';
import { LoginScreen } from '../pages/authentication';
import {
  ProductsScreen, ModifyProductScreen, DeleteProductScreen, AddProductScreen, TableScreen,
} from '../pages/products';
import { StatisticsScreen } from '../pages/statistics';
import { CategoriesScreen } from '../pages/categories';
import { ProfileScreen } from '../pages/profile';
import { OrderScreen } from '../pages/orders';

import PrivateRoute from './PrivateRoute';

export default function Routes() {
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) localStorage.setItem('uid', user.uid);
      else localStorage.setItem('uid', '');
    });
  });
  return (
    <Switch>
      {/* <Redirect from="/" to="/login" /> */}
      <Route path="/" exact component={LoginScreen} />
      <PrivateRoute path="/products" exact component={ProductsScreen} />
      <PrivateRoute path="/statistics" exact component={StatisticsScreen} />
      <PrivateRoute path="/products/:ref/modify" component={ModifyProductScreen} />
      <PrivateRoute path="/products/:ref/delete" component={DeleteProductScreen} />
      <PrivateRoute path="/products/addProduct" component={AddProductScreen} />
      <PrivateRoute path="/products/addCSV" component={TableScreen} />
      <PrivateRoute path="/categories" component={CategoriesScreen} />
      <PrivateRoute path="/orders" exact component={OrderScreen} />
      <PrivateRoute path="/profile" exact component={ProfileScreen} />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      {/* <Route component={SignIn} /> */}
    </Switch>
  );
}
