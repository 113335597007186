import db from './config';
import { autoCapitalize } from './kebab-case';

export const getAllCategories = async () => {
  let categs = [];
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('OPT')
    .doc('allCategories')
    .get()
    .then((doc) => {
      try {
        const { categories } = doc.data();
        categs = categories;
      } catch (error) {
        categs = [];
      }
    });

  return categs;
};

export const addNewCategory = async (newCateg) => {
  let docId = '';
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .add({
      name: autoCapitalize(newCateg)
    })
    .then((docRef) => {
      docId = docRef.id;
    });
  return docId;
};

export const addNewSubcategory = async (categId, name) => {
  let docId = '';
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .add({
      name: autoCapitalize(name)
    })
    .then((docRef) => {
      docId = docRef.id;
    });
  return docId;
};

export const updateAllCategories = async (categories) => {
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('OPT')
    .doc('allCategories')
    .set({
      categories
    })
    .catch((error) => {
      console.error('Error writing document: ', error);
    });
};

export const updateCategoryName = async (categId, categName, image) => {
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .set({
      name: autoCapitalize(categName),
      image
    });
};

export const updateSubcategoryName = async (categId, subcategId, name) => {
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .update({
      name: autoCapitalize(name)
    });
};

export const updateProductCategory = async (categId, subcategId, productId, newName) => {
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .collection('PRODUCTS')
    .doc(productId)
    .update({
      'categ.name': autoCapitalize(newName)
    });
};

export const updateProductSubcategory = async (categId, subcategId, productId, newName) => {
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('CATEG')
    .doc(categId)
    .collection('SUBCATEG')
    .doc(subcategId)
    .collection('PRODUCTS')
    .doc(productId)
    .update({
      'subcateg.name': autoCapitalize(newName)
    });
};

export default db;
