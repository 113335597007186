import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useTable, usePagination, useFilters, useSortBy
} from 'react-table';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button, Alert
} from 'reactstrap';
import { parse } from 'papaparse';
import { AiOutlinePicture } from 'react-icons/ai';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Title from '../../components/navigation/Title';
import './styles/tableScreen.css';
import { SelectFilter, Filter } from '../../components/products/TableDropDown';
import { UploadToStorage } from '../../firestore/storage';
import {
  addNewProduct, updateAllProducts, addOptions
} from '../../firestore/products';
import {
  addNewSubcategory, addNewCategory, updateAllCategories
} from '../../firestore/categories';

function DisplayTable({
  columns, data, updateData, deleteData, tickbox, settickBox, selectAll, setselectAll,
}) {
  const {
    headerGroups,
    page,
    pageOptions,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable({
    columns,
    data,
    tickbox,
    settickBox,
    selectAll,
    setselectAll,
    updateData,
    deleteData,
    initialState: { pageSize: 15 }
  },
  useFilters, useSortBy, usePagination);

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center mb-2 mt-3 font-weight-bold">
        {pageOptions.length === 0
          ? <span>Página {pageIndex} de {pageOptions.length}</span>
          : <span>Página {pageIndex + 1} de {pageOptions.length}</span>}
        <div className="d-flex align-items-center justify-content-center ml-3">
          <button type="button" className="bg-transparent" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <FiChevronLeft size={20} />
          </button>
          <button type="button" className="ml-4 bg-transparent" onClick={() => nextPage()} disabled={!canNextPage}>
            <FiChevronRight size={20} />
          </button>
        </div>
      </div>
      <table className="table-light table-border table-style table-responsive-md">
        <thead>
          <tr>
            {headerGroups[0].headers.map((header) => (
              <td className="font-weight-bold py-1 px-3">
                <div className="d-flex flex-row">
                  {header.render('Header')}
                  <Filter header={header} />
                </div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr>
                {row.cells.map((cell) => <td>{cell.render('Cell', { index: i, cell })}</td>)}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

const ImageCell = ({ updateData, row: { index, cells, original }, column: { id }, }) => {
  let image;
  if (typeof (cells[0].value) === 'object') {
    image = URL.createObjectURL(cells[0].value);
  } else {
    image = cells[0].value;
  }

  const handleImage = (event) => {
    const imageFile = event.target.files[0];
    // setImage(URL.createObjectURL(event.target.files[0]));
    updateData(index, id, imageFile, original);
  };

  return (
    <label className="d-flex align-items-center justify-content-center m-0">
      <img className="img-fluid" alt="" src={image} />
      <AiOutlinePicture size={25} />
      <input id={index} className="d-none" type="file" onChange={handleImage} />
    </label>
  );
};

const DeleteRow = ({ deleteData, row: { index, original }, }) => (
  <div className="d-flex align-items-center justify-content-center m-0">
    <button type="button" className="bg-transparent">
      <RiDeleteBinLine size={30} onClick={() => deleteData(index, original)} />
    </button>
  </div>
);

const TableScreen = () => {
  const [file, setFile] = useState('');
  const [data, setData] = useState([]);
  const [originalData, setOriginal] = useState([]);
  const [tickbox, settickBox] = useState([]);
  const [imgArr, setimgArr] = useState([]);
  const [modelVisible, setModal] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selectAll, setselectAll] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const initialProducts = () => JSON.parse(window.localStorage.getItem('allProducts')) || [];
  const initalCategories = () => JSON.parse(window.localStorage.getItem('allCategories')) || [];
  const [products, setProducts] = useState(initialProducts);
  const [categories, setCategories] = useState(initalCategories);

  const toggleModal = () => setModal(!modelVisible);
  const toggleAlert = () => setAlert(!alert);

  // const handleFile = (event) => {
  //   setFile(event.target.files[0].name);
  //   parse(event.target.files[0], {
  //     header: true,
  //     complete(results) {
  //       results.data.pop();
  //       setData(results.data);
  //       setOriginal(results.data);
  //     }
  //   });
  // };
  useEffect(() => {
    console.log(location.data);
    setData(location.data);
    setOriginal(location.data);
  }, [location]);

  const columns = useMemo(() => [
    {
      Header: 'Añadir imagen',
      Cell: ImageCell,
      accessor: 'imageURL',
      disableFilters: true
    },
    {
      Header: 'Nombre',
      accessor: 'name',
      disableFilters: true
    },
    {
      Header: () => (
        <div className="d-flex flex-row align-items-center">
          <span>Marca</span>
        </div>
      ),

      accessor: 'brand',
      Filter: SelectFilter,
      filter: 'includesSome',
      defaultCanSort: true
    },
    {
      Header: 'Código de barras',
      accessor: 'barCode',
      disableFilters: true,
    },
    {
      Header: () => (
        <div className="d-flex flex-row align-items-center">
          <span>Categoría</span>
        </div>
      ),
      accessor: 'categ',
      Filter: SelectFilter,
      filter: 'includesSome',
      defaultCanSort: true
    },
    {
      Header: () => (
        <div className="d-flex flex-row align-items-center">
          <span>Subcategoría</span>
        </div>
      ),
      accessor: 'subcateg',
      Filter: SelectFilter,
      filter: 'includesSome',
      defaultCanSort: true
    },
    {
      Header: 'Color',
      accessor: 'colour',
      disableFilters: true
    },
    {
      Header: 'Tamaño',
      accessor: 'size',
      disableFilters: true
    },
    {
      Header: 'Precio',
      accessor: 'price',
      disableFilters: true
    },
    {
      Header: 'Eliminar producto',
      Cell: DeleteRow,
      disableFilters: true
    }
  ]);

  const deleteData = (rowIndex, original) => {
    const tmp = [...data];
    tmp.splice(rowIndex, 1);
    setData(tmp);
    setOriginal(originalData.filter((item) => {
      if (item.brand === original.brand && item.colour === original.colour && item.size === original.size) {
        return false;
      }
      return true;
    }));
  };

  const updateData = async (rowIndex, colIndex, imageFile, original) => {
    const tmp = [...originalData];
    setData((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [colIndex]: imageFile,
        };
      }
      return row;
    }));
    const found = originalData.findIndex((current) => current === original);
    // const url = await UploadToStorage('products', imageFile);
    tmp[found].imageURL = imageFile;
    setOriginal(tmp);
  };

  const saveChanges = () => {
    let enableSave = true;
    let i;
    for (i = 0; i < data.length; i += 1) {
      if (data[i].imageURL === '') {
        enableSave = false;
        break;
      }
    }
    if (enableSave) {
      toggleModal();
    } else {
      setAlert(true);
    }
    return enableSave;
  };

  const getimageURL = async (inputImage) => {
    let url;
    if (typeof (inputImage) === 'object') {
      url = await UploadToStorage('products', inputImage);
    } else { url = inputImage; }
    return url;
  };

  const dataReconstruct = async () => {
    let i = 0;
    let nums = [];
    let index = 0;

    const output = [];
    const images = [];
    const isSave = saveChanges();
    if (isSave === true) {
      while (index < originalData.length - 1) {
        nums.push(originalData[index]);
        for (i = index + 1; i < originalData.length; i += 1) {
          const dupProduct = nums.findIndex((item) => item.barCode === originalData[i].barCode);
          if (dupProduct !== -1) {
            nums.push(originalData[i]);
            index += 1;
          } else {
            index = i;
            break;
          }
        }
        const option = [];
        const imgs = [];
        // let url;
        nums.forEach(async (element) => {
        // if (typeof (element.imageURL) === 'object') {
        //   url = await UploadToStorage('products', element.imageURL);
        // } else { url = element.imageURL; }
          const url = await getimageURL(element.imageURL);
          console.log('luku', url);
          const dupColour = option.findIndex((item) => item.colour === element.colour);
          if (dupColour === -1) {
            option.push({
              colour: element.colour,
              prices: [{
                price: element.price,
                size: element.size,
                image: url
              }]
            });
          } else {
            option[dupColour].prices.push({
              price: element.price,
              size: element.size,
              image: url
            });
          }
          imgs.push(url);
        });
        output.push({
          name: nums[0].name,
          brand: nums[0].brand,
          categ: nums[0].categ,
          subcateg: nums[0].subcateg,
          price: nums[0].price,
          barCode: nums[0].barCode,
          options: option
        });
        // images.push(imgs);
        setimgArr((old) => [...old, imgs]);
        console.log(images);
        nums = [];
      }
      setOriginal(output);
    }
  };

  const savetodb = async () => {
    let CategID;
    let SubcategID;
    let docId;
    const unique = [...products];
    const AllCateg = [...categories];
    // const { images, output } = await dataReconstruct();
    await Promise.all(originalData.map(async (current, j) => {
      let categ = {};
      let subcategories = {};
      let prodInfo = {};
      const found = categories.findIndex((item) => item.name === current.categ);
      // if categ exist
      if (found !== -1) {
        CategID = categories[found].id;
        categ = { name: current.categ, id: CategID };
        const sub = categories[found].subcateg.findIndex((item) => item.name === current.subcateg);
        // id subcateg exist
        if (sub !== -1) {
          SubcategID = categories[found].subcateg[sub].id;
          subcategories = { name: current.subcateg, id: SubcategID };
        } else {
          SubcategID = await addNewSubcategory(CategID, current.subcateg);
          subcategories = { name: current.subcateg, id: SubcategID };
          AllCateg[found].subcateg.push(subcategories);
          await updateAllCategories(AllCateg);
        }
      } else {
        CategID = await addNewCategory(current.categ, '');
        categ = { name: current.categ, id: CategID };
        SubcategID = await addNewSubcategory(CategID, current.subcateg);
        subcategories = { name: current.subcateg, id: SubcategID };
        const NewCateg = {
          id: CategID,
          image: '',
          name: current.categ,
          subcateg: [{
            id: SubcategID,
            name: current.subcateg
          }]
        };
        await updateAllCategories([...AllCateg, { ...NewCateg }]);
      }
      prodInfo = {
        name: current.name,
        brand: current.brand,
        categ,
        subcateg: subcategories,
        price: current.price,
        barCode: current.barCode,
        images: imgArr[j]
      };
      docId = await addNewProduct(CategID, SubcategID, prodInfo);
      unique.push({ ...prodInfo, id: docId });
      await addOptions(CategID, subcategories.id, docId, current.options);
    }));
    setProducts(unique);
    await updateAllProducts(unique);
    toggleModal();
    history.goBack();
  };

  return (
    <div className="d-flex container-fluid flex-column">
      <Title title="PRODUCTOS" buttonText="Guardar cambios" buttonAction={dataReconstruct} />
      <Alert color="danger" isOpen={alert} toggle={toggleAlert}>
        Por favor compruebe que todos los campos han sido rellenados.
      </Alert>
      <div className="container">
        <div className="d-flex justify-content-center">
          <DisplayTable
            columns={columns}
            data={data}
            updateData={updateData}
            deleteData={deleteData}
            tickbox={tickbox}
            settickBox={settickBox}
            selectAll={selectAll}
            setselectAll={setselectAll}
          />
        </div>
        <Modal isOpen={modelVisible} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Confirmar Nuevo Producto</ModalHeader>
          <ModalBody>
            Upload <b>{file}</b>?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => savetodb()}>Confimar</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default TableScreen;
