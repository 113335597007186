import React, { useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
  Carousel, CarouselItem, CarouselControl,
} from 'reactstrap';

const ImageCarousel = ({ imagesArray, setImageArray, labelControl }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === imagesArray.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? imagesArray.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const removeProdImg = (index) => {
    const tmp = [...imagesArray];
    tmp.splice(index, 1);
    setImageArray(tmp);
    setActiveIndex(index - 1);
  };

  const slides = imagesArray.map((item, index) => {
    let img = '';
    if (typeof item !== 'string') {
      img = URL.createObjectURL(item);
    } else {
      img = item;
    }
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item}
      >
        <label htmlFor={labelControl} className="d-flex w-100 justify-content-center align-self-center">
          <div className="d-flex align-items-center justify-content-center w-100 bg-white productImg">
            <button
              type="button"
              className="d-flex position-absolute  align-self-start justify-self-start bg-white rounded-circle p-1 m-2"
              style={{ right: 0, zIndex: 1000 }}
              onClick={() => removeProdImg(index)}
            >
              <RiDeleteBinLine size={20} />
            </button>
            <img src={img} alt={item.name} className="img-fluid mh-100 mw-100" />
          </div>
        </label>
      </CarouselItem>
    );
  });

  const indicators = imagesArray.map((item, index) => {
    let tmp = '';
    let img = '';
    if (activeIndex === index) {
      tmp = 'd-flex my-2 mx-1 previewImg p-0 border border-dark align-items-center';
    } else {
      tmp = 'd-flex my-2 mx-1 previewImg p-0 border align-items-center';
    }

    if (typeof item !== 'string') {
      img = URL.createObjectURL(item);
    } else {
      img = item;
    }
    return (
      <li data-slide-to={index} className="d-flex col-2 col-md-4 p-0 w-100 align-items-center">
        <button type="button" className={tmp} onClick={() => goToIndex(index)}>
          <img src={img} alt={item.name} className="img-fluid rounded" />
        </button>
      </li>
    );
  });

  return (
    <div className="d-flex flex-column">
      <Carousel
        activeIndex={activeIndex}
      // next={next}
      // previous={previous}
        id="#imagesList"
        className="d-flex"
      >
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>

      <ul className="row mx-3 mx-md-0">
        {indicators}
      </ul>

    </div>
  );
};

export default ImageCarousel;
