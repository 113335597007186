/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { GrAdd } from 'react-icons/gr';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button, Alert
} from 'reactstrap';

import { useHistory } from 'react-router-dom';
import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';
import {
  updateAllProducts, addNewProduct, addOptions
} from '../../firestore/products';

import {
  disableAddOptions, mergeOptions, handleAddOption, showOptions
} from '../../helpers/products';

import './styles/productsScreens.css';
import Dropdown from '../../components/products/Dropdown';
import { UploadToStorage } from '../../firestore/storage';
import ImageCarousel from '../../components/products/ImageCarousel';

function AddProductScreen() {
  const history = useHistory();
  const initialProducts = () => JSON.parse(window.localStorage.getItem('allProducts')) || [];
  const initalCategories = () => JSON.parse(window.localStorage.getItem('allCategories')) || [];
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState({ name: 'Selecciona', id: '' });
  const [subCategory, setSubcategory] = useState({ name: 'Selecciona', id: '' });
  const [barCode, setBarCode] = useState('');
  const [details, setDetails] = useState('');

  const [options, setOptions] = useState([{
    type: '',
    price: '',
    size: '',
    image: '',
  }]);
  const categories = initalCategories();
  const [subcategories, setSubcategories] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [allProducts, setAllProducts] = useState(initialProducts);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [productImages, setProductImages] = useState([]);

  const toggleModal = () => setShowModal(!showModal);
  const toggleAlert = () => setShowAlert(!showAlert);

  useEffect(() => {
    window.localStorage.setItem('allProducts', JSON.stringify(allProducts));
  }, [allProducts]);

  useEffect(() => {
    disableAddOptions(options, setIsDisabled);
  });

  useEffect(() => {
    getSubcategories();
  }, [category]);

  const getSubcategories = () => {
    const index = categories.findIndex((item) => item.id === category.id);
    if (index !== -1) {
      setSubcategories(categories[index].subcateg);
    }
  };

  const handleProductImages = (e) => {
    setProductImages((old) => [...old, ...Object.values(e.target.files)]);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleBrand = (e) => {
    setBrand(e.target.value);
  };

  const selectCateg = (catego) => {
    setCategory({
      name: catego.name,
      id: catego.id
    });

    setSubcategory({ name: 'Selecciona' });
  };

  const selectSubcateg = (subCateg) => {
    setSubcategory(subCateg);
  };

  const handleBarCode = (e) => {
    setBarCode(e.target.value);
  };

  const handleDetails = (e) => {
    setDetails(e.target.value);
  };

  const saveChanges = () => {
    let enabledSave = true;
    if (name !== '' && brand !== '' && barCode !== '' && productImages.length !== 0
    && subCategory.name !== 'Selecciona' && options.length !== 0) {
      // console.log(options);
      options.map((item) => {
        if (item.type !== '' && item.price !== '' && item.image !== '') {
          enabledSave = true;
        } else enabledSave = false;
      });

      if (enabledSave) {
        toggleModal();
      } else {
        setShowAlert(true);
      }
    } else {
      setShowAlert(true);
    }
  };

  const savetodb = async () => {
    const {
      unique, lowestPrice
    } = await mergeOptions(options, setOptions, category, subCategory, name);

    const docId = await addNewProduct(category.id, subCategory.id, { brand, name, barCode });
    addOptions(category.id, subCategory.id, docId, unique);
    const url = await uploadProductImages();

    const prodInfo = {
      name,
      brand,
      images: url,
      categ: category,
      subcateg: subCategory,
      price: lowestPrice,
      barCode,
      id: docId,
      details
    };

    updateAllProducts([...allProducts, prodInfo]);
    setAllProducts([...allProducts, prodInfo]);
    toggleModal();
    history.goBack();
  };

  const uploadProductImages = async () => {
    const tmp = [];
    await Promise.all(productImages.map(async (item) => {
      const url = await UploadToStorage(`companies/companyName/category/${category.name}/${subCategory.name}/${name}`, item);
      tmp.push(url);
    }));

    return tmp;
  };

  const confirmNewProduct = () => (
    <Modal isOpen={showModal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Confirmar Nuevo Producto</ModalHeader>
      <ModalBody>
        ¿Está seguro de que quiere crear un nuevo producto?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={savetodb}>Confimar</Button>
        <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <div className="d-flex container-fluid flex-column">
      <Title title="AÑADIR PRODUCTO" buttonText="Guardar cambios" buttonAction={saveChanges} />
      <div className="d-flex container-fluid p-0 h-100">
        <SideNavBar />
        {confirmNewProduct()}
        <div className="container p-3 mt-4">
          <Alert color="danger" isOpen={showAlert} toggle={toggleAlert}>
            Por favor compruebe que todos los campos han sido rellenados.
          </Alert>
          <div className="row m-3">
            <span className="row col-12">
              1. Información general del producto
            </span>
            <div className="col-12 col-md-3 d-flex align-items-center mt-3 p-0">
              { productImages.length !== 0 ? (
                <ImageCarousel imagesArray={productImages} setImageArray={setProductImages} labelControl="addProductImgs" />
              )
                : (
                  <label htmlFor="addProductImgs" className="d-flex w-100 justify-content-center align-self-center">
                    <span className="d-flex justify-content-center align-items-center text-center py-2 border border-dark rounded-0" style={{ height: 150 }}>
                      Insertar imágenes
                    </span>
                  </label>
                )}
              <input className="d-none" type="file" id="addProductImgs" onChange={handleProductImages} multiple />

            </div>
            <div className="col-12 col-md-9">
              <ul className="row my-4">
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Nombre del producto</b>
                    <input value={name} id="name" onChange={handleName} className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Marca del Producto</b>
                    <input value={brand} id="brand" onChange={handleBrand} className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <Dropdown title="Categoría" options={categories} setOption={selectCateg} selectedOption={category} disable={false} />
                </li>
                <li className="col-12 col-sm-6">
                  <Dropdown title="Subcategoria" options={subcategories} setOption={selectSubcateg} selectedOption={subCategory} disable={false} />
                </li>
                <li className="col-12 col-sm-6 ">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Código de barras</b>
                    <input value={barCode} id="reference" onChange={handleBarCode} className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
                <li className="col-12 col-sm-6 ">
                  <div className="d-flex flex-column mx-lg-3">
                    <span className="text-secondary"> <b className="text-dark">Detalles del producto</b> (Opcional)</span>
                    <textarea value={details} id="reference" onChange={handleDetails} rows="3" className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row m-3">
            <span className="row col-12">
              2. Opciones de tamaño y color
            </span>
            <ul className="row container">
              {options === undefined ? <div /> : showOptions(options, setOptions, false)}

              <li className="my-5 col-12 col-md-6 d-flex align-items-center justify-content-center">
                <button type="button" disabled={isDisabled} onClick={() => handleAddOption(options, setOptions)} className="no-outline bg-white border-dark py-2 px-4 border rounded-5">
                  <GrAdd size={25} />
                  <span className="pl-3">Añadir opción de color y/o tamaño</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddProductScreen;
