import React, { useState, useEffect } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';

import { FiChevronDown } from 'react-icons/fi';
import { TiPencil, TiTick } from 'react-icons/ti';
import { RiDeleteBinLine } from 'react-icons/ri';
import { GrAdd } from 'react-icons/gr';

import './styles/subCategDropdown.css';

const Dropdown = ({
  title, options, setSelectedOption, selectedOption, setOptions
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addButton, setAddButton] = useState('');
  const [subcategIndex, setSubcategIndex] = useState(0);
  const [showSubcategAlert, setShowSubcategAlert] = useState(false);

  const toggleSubcategAlert = () => setShowSubcategAlert(!showSubcategAlert);

  if (selectedOption === '') setSelectedOption('Añadir, modificar o eliminar');

  useEffect(() => {
    try {
      const input = addButton.parentElement.nextElementSibling.firstChild.firstChild;
      input.removeAttribute('disabled');
      input.focus();
    } catch (error) {
      console.log(error);
    }
  }, [options.length]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const addItem = (e) => {
    const tmp = [...options];
    let add = true;
    tmp.forEach((item) => {
      if (item.name === '') {
        add = false;
      }
    });

    if (add) {
      tmp.unshift({
        name: '',
        id: ''
      });
      setOptions(tmp);
    } else {
      try {
        const input = e.target.parentElement.nextElementSibling.firstChild.firstChild;
        input.removeAttribute('disabled');
        input.focus();
      } catch (error) {
        console.log(error);
      }
    }
    setAddButton(e.target);
  };

  const deleteSubcategory = () => {
    if (options.length !== 1) {
      const tmp = [...options];
      tmp.splice(subcategIndex, 1);
      setOptions(tmp);
    }
    setSubcategIndex(0);
    toggleSubcategAlert();
  };

  const focusInput = (e) => {
    const elem = e.target.closest('div').previousElementSibling.firstChild;
    elem.removeAttribute('disabled');
    elem.focus();
  };

  const blurInput = (e) => {
    const elem = e.target;
    elem.blur();
    elem.setAttribute('disabled', 'true');
  };

  const keypressHandler = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const handleinput = (e, id) => {
    const tmp = [...options];
    tmp[e.target.name] = {
      name: e.target.value,
      id
    };
    setOptions(tmp);
  };

  const confirmDeleteSubcateg = (i) => {
    if (options.length !== 1) {
      toggleSubcategAlert();
      setSubcategIndex(i);
    }
  };

  const dropdownOptions = () => {
    const t = [...options];
    return (
      t.map((item, index) => (
        <div className="row d-flex dropdown-item px-1 mx-0" key={`${item.id}/${index}`}>
          <div className="col-12 col-md-9 d-flex align-items-center">
            <input
              className="bg-transparent w-100 dropdown-input"
              type="text"
              value={item.name}
              disabled
              name={index}
              onFocus={(e) => {
                const val = e.target.value;
                e.target.value = '';
                e.target.value = val;
              }}
              onChange={(e) => handleinput(e, item.id)}
              onBlur={blurInput}
              onKeyPress={keypressHandler}
            />
          </div>
          <div className="col-12 col-md-3 d-flex align-items-center justify-content-end">
            {/* <button type="button" className="btn bg-transparent border-0 shadow-none" data-toggle="tooltip" title="Guardar cambios">
              <TiTick size={20} />
            </button> */}
            <button type="button" className="btn bg-transparent border-0 shadow-none pointer" onClick={focusInput} data-toggle="tooltip" title="Modificar Subcategoria">
              <TiPencil size={20} />
            </button>
            <button type="button" className="btn bg-transparent border-0 shadow-none" onClick={() => confirmDeleteSubcateg(index)} data-toggle="tooltip" title="Eliminar Subcategoria">
              <RiDeleteBinLine size={20} />
            </button>
          </div>
        </div>
      )));
  };

  const deleteOptionModal = () => (
    <Modal isOpen={showSubcategAlert} toggle={toggleSubcategAlert}>
      <ModalHeader toggle={toggleSubcategAlert}>Eliminar subcategoria</ModalHeader>
      <ModalBody>
        ¿Está seguro de que quiere eliminar la subcategoría <strong>{options[subcategIndex].name}</strong>?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={deleteSubcategory}>Confimar</Button>
        <Button color="secondary" onClick={toggleSubcategAlert}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <div className="d-flex flex-column my-3">
      {deleteOptionModal()}
      <b>{title}</b>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle color="light" className="d-flex justify-content-between py-2 px-2 border my-2 rounded-0">
          {selectedOption}
          <FiChevronDown size={25} />
        </DropdownToggle>
        <DropdownMenu className="w-100">
          <button type="button" onClick={addItem} className="d-flex dropdown-item justify-content-center align-items-center">
            <GrAdd size={20} /> <span className="pl-1">Añadir subcategoría</span>
          </button>
          {options === undefined ? <div /> : dropdownOptions()}
          {/* {dropdownOptions()} */}
        </DropdownMenu>
      </ButtonDropdown>

    </div>
  );
};

export default Dropdown;
