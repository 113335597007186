import React from 'react';

import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';

function StatisticsScreen() {
  return (
    <div className="root">
      <Title title="Estadisticas" />
      <div className="greyBackground">
        <SideNavBar title="Productos" />
      </div>
    </div>
  );
}

export default StatisticsScreen;
