import imageCompression from 'browser-image-compression';
import { storageRef } from './config';

export const UploadToStorage = async (folder, file) => {
  // console.log(file);
  let urlArr;
  const options = {
    maxSizeMB: 2
  };
  await imageCompression(file, options)
    .then(async (compressedFile) => {
      const fileName = folder.concat('/', file.name);
      await storageRef.child(fileName).put(compressedFile)
        .then(async () => {
          console.log('upload successfully');
          await storageRef.child(fileName).getDownloadURL()
            .then((url) => {
              console.log('url: ', url);
              urlArr = url;
            });
        });
    })
    .catch((e) => console.log('error', e.message));
  return urlArr;
};

export default storageRef;
