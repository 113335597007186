import React from 'react';
import { UploadToStorage } from '../firestore/storage';
import OptionsItem from '../components/products/OptionsItem';

const disableAddOptions = (options, setIsDisabled) => {
  setIsDisabled(false);
  options.every((item) => {
    if (item.type === '' || item.price === '' || item.image === '') {
      setIsDisabled(true);
      return false;
    }
    return true;
  });
};

const mergeOptions = async (options, setOptions, category, subCategory, name) => {
  const unique = [];
  let lowestPrice = options[0].price;
  let url = '';
  await Promise.all(options.map(async (current) => {
    if (lowestPrice > current.price) lowestPrice = current.price;
    if (typeof current.image === 'string') {
      url = current.image;
    } else {
      url = await UploadToStorage(`companies/companyName/category/${category.name}/${subCategory.name}/${name}/options`, current.image);
    }

    const x = unique.findIndex((item) => item.type === current.type);
    if (x === -1) {
      unique.push({
        type: current.type,
        prices: [{
          price: current.price,
          image: url,
          size: current.size,
        }]
      });
    } else {
      unique[x].prices.push({
        price: current.price,
        image: url,
        size: current.size,
      });
    }
  }));

  setOptions(unique);
  return { unique, lowestPrice };
};

const handleAddOption = (options, setOptions) => {
  const emptyOption = {
    type: '',
    price: '',
    size: '',
    image: '',
  };

  if (options === undefined) {
    setOptions(emptyOption);
  } else {
    setOptions([...options, emptyOption]);
  }
};

const showOptions = (options, setOptions, disabledDelete, deleteFunction, toggleAlert) => {
  try {
    return (
      options.map((item, optIndex) => (
        <OptionsItem
          item={item}
          disable={disabledDelete}
          deleteFunction={deleteFunction}
          setOptions={setOptions}
          options={options}
          optIndex={optIndex}
          toggleAlert={toggleAlert}
          key={`${item.type}/${item.size}`}
        />
      ))
    );
  } catch (error) {
    return (<div />);
  }
};

export {
  disableAddOptions,
  mergeOptions,
  handleAddOption,
  showOptions
};
