import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import './styles/productListItem.css';
import { TiPencil } from 'react-icons/ti';
import { RiDeleteBinLine } from 'react-icons/ri';

const ProductListItem = ({ product }) => {
  const {
    name, brand, price, categ, subcateg, images, id, barCode
  } = product;

  const { url } = useRouteMatch();
  return (
    <li className="container my-2">
      <div className="row align-content-center bg-white">
        <div className="col-12 col-sm-6 col-md-2 d-flex flex-column justify-content-center align-items-center order-md-1 order-sm-1 py-3">
          <img className="image" src={images[0]} alt="product" />
        </div>

        <div className="col-12 col-sm-6 col-md-3 d-flex flex-column justify-content-center order-sm-2 order-md-2 py-3">
          <div className="p-1">{name}</div>
          <div className="p-1">
            Marca: {brand}
          </div>
          <div className="p-1">
            Precio: {Number(price).toFixed(2)}€
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-4 d-flex flex-column justify-content-center order-sm-4 order-md-3 py-3">
          <div className="p-1">
            Categoría: {categ.name}
          </div>
          <div className="p-1">
            Subcategoría: {subcateg.name}
          </div>
          <div className="p-1">{barCode}</div>
        </div>

        <div className="col-12 col-sm-6 col-md-3 d-flex flex-column justify-content-center order-sm-3 order-md-4 py-3">
          <a className="d-flex align-content-center justify-content-center text-decoration-none text-reset border border-dark py-1" style={{ margin: 5 }} href={`${url}/${id}/modify`}>
            <TiPencil size={20} />
            <span className="px-2">Modificar</span>
          </a>
          <a className="d-flex align-content-center justify-content-center text-decoration-none text-reset border border-dark py-1" style={{ margin: 5 }} href={`${url}/${id}/delete`}>
            <RiDeleteBinLine size={20} />
            <span className="px-2">Eliminar</span>
          </a>
        </div>
      </div>
    </li>
  );
};

export default ProductListItem;
