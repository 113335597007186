import db from './config';

export const getAdmin = async () => {
  let adminInfo = {};
  await db
    .collection('COMPANIES')
    // TODO: need to change this
    .doc('NPeRzd7lbcI1Ic277RQg')
    .get()
    .then((doc) => {
      const info = doc.data();
      // eslint-disable-next-line prefer-destructuring
      const id = doc.id;
      // adminInfo = info;
      adminInfo = { info, id };
    });
  return adminInfo;
};

export const updateAdmin = async (docid, adminInfo) => {
  // let isUpdated = false;
  await db
    .collection('COMPANIES')
    .doc(docid)
    .update(adminInfo)
    .then(() => {
      // isUpdated = true;
      console.log('update successfully');
    })
    .catch((e) => {
      console.log(e);
    });
  // return isUpdated;
};

export const OrderRef = async () => {
  const snapshot = [];
  await db
    .collection('USERS')
    .get()
    .then((query) => {
      query.forEach((doc) => {
        const t = doc.ref.collection('ORDERS');
        snapshot.push(t);
      });
    });
  return snapshot;
};

export const getOrders = async () => {
  const orders = [];
  await db
    .collection('COMPANIES')
    .doc('NPeRzd7lbcI1Ic277RQg')
    .collection('ORDERS')
    .get()
    .then((snapshot) => {
      snapshot.forEach((doc) => orders.unshift({ ...doc.data(), id: doc.id }));
    });
  return orders;
};
