import React, { useState, useEffect } from 'react';
import { GrAdd } from 'react-icons/gr';
import {
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from 'reactstrap';

import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';
import CategListItem from '../../components/categories/CategListItem';
import SubCategDropdown from '../../components/categories/SubCategDropdown';
import './styles/categoriesScreen.css';

import {
  addNewCategory, addNewSubcategory, updateAllCategories
} from '../../firestore/categories';
import { UploadToStorage } from '../../firestore/storage';
import { autoCapitalize } from '../../firestore/kebab-case';

function CategoriesScreen() {
  const initalCategories = () => JSON.parse(window.localStorage.getItem('allCategories')) || [];
  const [categs, setCategs] = useState(initalCategories);
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState('');
  const [fileName, setfileName] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [subcategs, setSubcategs] = useState([{ name: '', id: '' }]);
  const [newCategory, setNewCategory] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [showNewCategAlert, setShowNewCategAlert] = useState(false);

  const toggle = () => {
    setNewCategory('');
    setSubcategs([{ name: '', id: '' }]);
    setfileName('');
    setFile('');
    setShowNewCategAlert(false);
    setIsOpen(!isOpen);
  };

  const toggleNewCategAlert = () => setShowNewCategAlert(false);

  useEffect(() => {
    window.localStorage.setItem('allCategories', JSON.stringify(categs));
  }, [categs]);

  function items() {
    return (categs.map((item, index) => (
      <CategListItem
        index={index}
        image={item.image}
        name={item.name}
        categId={item.id}
        subCategories={item.subcateg}
        setSubcategs={setSubcategs}
        setCategories={setCategs}
        categories={categs}
        key={`${item.name}`}
      />
    ))
    );
  }

  function handleFileInputChange(event) {
    setFile(URL.createObjectURL(event.target.files[0]));
    setfileName(event.target.files[0]);
  }

  function handleNewCategory(e) {
    setNewCategory(e.target.value);
  }

  async function saveChanges() {
    const categId = await addNewCategory(newCategory);
    const subcategsAux = [...subcategs];
    Promise.all(subcategsAux.map(async (item, index) => {
      const subcategId = await addNewSubcategory(categId, item.name);
      subcategsAux[index].id = subcategId;
    }));

    const url = await UploadToStorage(`companies/companyName/category/${newCategory}`, fileName);
    const tmp = [...categs];
    tmp.push({
      name: autoCapitalize(newCategory),
      image: url,
      subcateg: subcategs,
      id: categId
    });

    tmp.sort((a, b) => a.name.localeCompare(b.name));
    setCategs(tmp);
    setSubcategs(subcategsAux);
    updateAllCategories(tmp);
    toggleModal();
    toggle();
  }

  function toggleModal() {
    let subcategOK = true;
    subcategs.forEach((item) => {
      if (item.name === '') subcategOK = false;
    });
    if (newCategory === '' || fileName === '' || !subcategOK) setShowNewCategAlert(true);
    else {
      setShowNewCategAlert(false);
      setShowModal(!showModal);
    }
  }

  function showOptions() {
    return (
      subcategs.map((item) => (
        <li className="ml-4" key={item}>
          {item.name} <br />
        </li>
      ))
    );
  }

  return (
    <div className="col-12 greyBackground container-fluid flex-column">
      <Title title="CATEGORÍAS" />
      <div className="container-fluid d-flex p-0 h-100">
        <SideNavBar />
        <div className="container">
          {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
          <Alert color="danger" isOpen={showNewCategAlert} toggle={toggleNewCategAlert}>
            Por favor compruebe que todos los campos han sido rellenados.
          </Alert>
          <Modal isOpen={showModal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Confirmar nueva categoría</ModalHeader>
            <ModalBody>
              Nombre de la nueva categoría: <b>{newCategory}</b> <br />
              Subcategorías: <br /> {showOptions()}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={saveChanges}>Confimar</Button>
              <Button color="secondary" onClick={toggleModal}>cancelar</Button>
            </ModalFooter>
          </Modal>

          <div className="row d-flex justify-content-center">
            <div className="col-10 col-md-6 d-flex justify-content-center">
              <button type="button" onClick={toggle} className="d-flex bg-white w-100 py-1 my-3 mx-2 border border-dark align-content-center justify-content-center btn shadow-none">
                { isOpen ? (<span className="px-2">Cancelar</span>)
                  : (
                    <div>
                      <GrAdd size={20} /><span className="px-2">Crear nueva categoría</span>
                    </div>
                  )}
              </button>
            </div>
            <div className="col-12 col-md-10">
              <Collapse isOpen={isOpen}>
                <div className="col-12 d-md-flex">
                  <div className="col-12 col-md-3 bg-white d-flex flex-column align-content-center justify-content-center m-4">
                    <label htmlFor="inputFile" className="d-flex flex-column w-100 h-100 justify-content-center align-self-center">
                      <img className="img-fluid" alt="" src={file} />
                      <span className="d-flex justify-content-center align-self-center text-center py-2">
                        { file === '' ? 'Insertar imagen' : 'Haz click para seleccionar otra imagen'}
                      </span>
                      <input id="inputFile" type="file" onChange={handleFileInputChange} />
                    </label>
                  </div>

                  <div className="col-12 col-md-9 p-4 py-5">
                    <div className="col-12 d-flex flex-column">
                      <form>
                        <span><b>Nombre de la nueva categoría</b></span>
                        <input type="text" className="form-control mt-2" value={newCategory} onChange={handleNewCategory} />
                        <SubCategDropdown
                          title="Subcategorías"
                          options={subcategs}
                          setSelectedOption={setSelectedOption}
                          selectedOption={selectedOption}
                          setOptions={setSubcategs}
                        />
                      </form>
                    </div>
                    <div className="col-12 d-flex justify-content-center align-items-center">
                      <button type="button" onClick={toggleModal} className="d-flex w-50 bg-red py-1 my-3 mx-2 text-white align-content-center border justify-content-center" href="/">
                        <span className="px-2">
                          Guardar cambios
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="container-fluid d-flex justify-content-center">
            <ul className="row col-12">
              {items()}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoriesScreen;
