import React from 'react';
import './styles/productList.css';

const ProductListItem = ({
  image, price, quantity, index
}) => (
  <li key={index} className="col-6 col-ml-4 col-lg-3 p-0">
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="container p-0 d-flex justify-content-center align-items-center">
        <img className="image" src={image} alt=" " />
      </div>
      <div className="d-flex flex-column align-items-center justift-content-center">
        <span>Cantidad: {quantity}</span>
        <span>Precio: {parseFloat(price).toFixed(2)}€</span>
      </div>
    </div>
  </li>
);

export default ProductListItem;
