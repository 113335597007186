import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, path }) => (
  <Route
    path={path}
    render={(props) => (localStorage.getItem('uid') !== '' ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />
    ))}
  />
);

export default PrivateRoute;
