import React, { useEffect, useState } from 'react';

import { RiDeleteBinLine } from 'react-icons/ri';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import { autoCapitalize } from '../../firestore/kebab-case';

const OptionsItem = ({
  item, disable, deleteFunction, setOptions, options, optIndex, toggleAlert
}) => {
  const {
    size, price, image, type
  } = item;
  const [oSize, setoSize] = useState(size);
  const [oPrice, setoPrice] = useState(price);
  const [oType, setoType] = useState(type);
  const [oImage, setoImage] = useState(typeof image === 'object' ? URL.createObjectURL(image) : image);
  const [showDeleteOption, setShowOptionProduct] = useState(false);
  // console.log('11111', options);

  const toggleDeleteOption = () => setShowOptionProduct(!showDeleteOption);
  const changeBg = () => {
    const tmp = document.getElementsByName('input');
    let i;
    for (i = 0; i < tmp.length; i += 1) {
      tmp[i].classList.add('bg-light');
    }
  };

  const handleFileInputChange = (event) => {
    if (event.target.files[0] !== undefined) {
      const tmp = event.target.files[0];
      Object.defineProperty(tmp, 'name', {
        writable: true,
        value: optIndex + tmp.name
      });
      setoImage(URL.createObjectURL(tmp));
      const optsAux = [...options];
      const changeImage = tmp;
      optsAux[optIndex].image = changeImage;
      setOptions(optsAux);
    }
  };

  useEffect(() => {
    if (disable) {
      changeBg();
    }
  }, []);

  const handleOnClick = () => {
    deleteFunction(size, image, type, price);
    toggleDeleteOption();
  };

  const checkDeleteOption = () => {
    if (options.length > 1) toggleDeleteOption();
    else toggleAlert();
  };

  const handleSize = (e) => {
    let tmp = e.target.value;
    if (tmp === undefined) tmp = '';
    setoSize(autoCapitalize(tmp));
  };

  const handlePrice = (e) => {
    setoPrice(Number(e.target.value));
  };

  const handleType = (e) => {
    setoType(autoCapitalize(e.target.value));
  };

  const updateSize = (e) => {
    const optsAux = [...options];
    optsAux[optIndex].size = oSize;
    setOptions(optsAux);
  };

  const updatetype = (e) => {
    const optsAux = [...options];
    optsAux[optIndex].type = oType;
    setOptions(optsAux);
  };

  const updatePrice = (e) => {
    const optsAux = [...options];
    optsAux[optIndex].price = Number(oPrice);
    setOptions(optsAux);
  };

  const deleteOptionModal = () => (
    <Modal isOpen={showDeleteOption} toggle={toggleDeleteOption}>
      <ModalHeader toggle={toggleDeleteOption}>
        ¿Estás seguro de que quieres eliminar esta opción?
      </ModalHeader>
      <ModalBody>
        La siguiente opción será eliminada:
        <ul>
          <li>Color: {type}</li>
          <li>Tamaño: {size}</li>
          <li>Precio: {price}</li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleOnClick}>Confimar</Button>{' '}
        <Button color="secondary" onClick={toggleDeleteOption}>Cancelar</Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <li className="my-3 col-12 col-md-6">
      <div className="row d-flex align-items-center">
        <div className="col-12 col-sm-5 col-lg-4">
          <label className="d-flex w-100 h-100 justify-content-center align-self-center">
            { oImage !== '' ? <img className="img-fluid border border-dark" alt="" src={oImage} />
              : (
                <span className="d-flex justify-content-center align-items-center text-center py-2 border border-dark rounded-0" style={{ height: 150 }}>
                  Insertar imagen
                </span>
              )}
            <input className="d-none" disabled={disable} type="file" onChange={handleFileInputChange} />
          </label>
        </div>

        <div className="col-12 col-sm-7 col-lg-8 my-1">
          <div className="d-flex flex-column mx-lg-3">
            <b>Tipo</b>
            <input disabled={disable} defaultValue={oType} onChange={handleType} onBlur={updatetype} name="input" className="py-1 px-2 border border-secondary my-2 rounded-0" />
          </div>
          <div className="d-flex flex-column mx-lg-3">
            <span className="text-secondary"><b className="text-dark">Tamaño</b> (Opcional)</span>
            <input disabled={disable} defaultValue={oSize} onChange={handleSize} onBlur={updateSize} name="input" className="py-1 px-2 border border-secondary my-2 rounded-0" />
          </div>
          <div className="d-flex flex-column mx-lg-3">
            <b>Precio</b>
            <input disabled={disable} type="number" defaultValue={oPrice} onChange={handlePrice} onBlur={updatePrice} name="input" className="py-1 px-2 border border-secondary my-2 rounded-0" />
          </div>
        </div>
      </div>

      {deleteOptionModal()}

      {disable
        ? (
          <Button
            className="col-12 col-sm-5 col-md-4 bg-transparent p-0"
            style={{ border: 'none', outline: 'none', boxShadow: 'none' }}
            onClick={checkDeleteOption}
          >
            <RiDeleteBinLine size={20} color="black" />
            <span className="pl-2 text-dark" style={{ fontSize: 15 }}>Eliminar opción</span>
          </Button>
        )
        : <div />}
    </li>
  );
};

export default OptionsItem;
