/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { GrAdd } from 'react-icons/gr';
import {
  Modal, ModalBody, ModalHeader, ModalFooter, Button, Alert
} from 'reactstrap';

import { useParams, useHistory } from 'react-router-dom';
import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';
// import OptionsItem from '../../components/products/OptionsItem';
import {
  addOptions, updateProductInfo, updateOptions, updateAllProducts, getProductOptions,
} from '../../firestore/products';

import { UploadToStorage } from '../../firestore/storage';
import {
  disableAddOptions, mergeOptions, handleAddOption, showOptions
} from '../../helpers/products';
import Dropdown from '../../components/products/Dropdown';
import ImageCarousel from '../../components/products/ImageCarousel';

const ModifyProductScreen = () => {
  const { ref } = useParams();
  const initialProducts = () => JSON.parse(window.localStorage.getItem('allProducts')) || [];
  const initalCategories = () => JSON.parse(window.localStorage.getItem('allCategories')) || [];
  const [name, setName] = useState('');
  const [brand, setBrand] = useState('');
  const [category, setCategory] = useState({});
  const [subCategory, setSubcategory] = useState({});
  const [barCode, setBarCode] = useState('');
  const [options, setOptions] = useState([]);

  const [productImages, setProductImages] = useState(['']);
  const [details, setDetails] = useState('');
  const categories = initalCategories();
  const [subcategories, setSubcategories] = useState([]);
  const [allProducts, setAllProducts] = useState(initialProducts);
  const [isDisabled, setIsDisabled] = useState(false);
  const [optionsId, setOptionsId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const history = useHistory();

  const toggleModal = () => setShowModal(!showModal);
  const toggleAlert = () => setShowAlert(!showAlert);

  useEffect(() => {
    getProducts();
    getSubcategories();
  }, []);

  useEffect(() => {
    disableAddOptions(options, setIsDisabled);
  });

  useEffect(() => {
    window.localStorage.setItem('allProducts', JSON.stringify(allProducts));
  }, [allProducts]);

  useEffect(() => {
    getSubcategories();
  }, [category]);

  async function getProducts() {
    const index = allProducts.findIndex((item) => item.id === ref);

    setCategory(allProducts[index].categ);
    setSubcategory(allProducts[index].subcateg);
    setName(allProducts[index].name);
    setBrand(allProducts[index].brand);
    setBarCode(allProducts[index].barCode);
    setProductImages(allProducts[index].images);
    setDetails(allProducts[index].details);

    getOptions(allProducts[index].categ.id, allProducts[index].subcateg.id, ref);
  }

  async function getOptions(categ, subcateg, id) {
    const opts = await getProductOptions(categ, subcateg, id);
    if (opts.options !== undefined) {
      const tmp = [];
      opts.options.forEach((item) => {
        item.prices.forEach((opt) => {
          tmp.push({
            type: item.type,
            size: opt.size,
            price: Number(opt.price),
            image: opt.image
          });
        });
      });
      setOptions(tmp);
      setOptionsId(opts.id);
    }
  }

  const getSubcategories = () => {
    const index = categories.findIndex((item) => item.id === category.id);
    if (index !== -1) {
      setSubcategories(categories[index].subcateg);
    }
  };

  const handleProductImages = (e) => {
    setProductImages((old) => [...old, ...Object.values(e.target.files)]);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleBrand = (e) => {
    setBrand(e.target.value);
  };

  const selectCateg = (catego) => {
    setCategory({
      name: catego.name,
      id: catego.id
    });

    setSubcategory({ name: 'Selecciona' });
  };

  const selectSubcateg = (subCateg) => {
    setSubcategory(subCateg);
  };

  const handleBarCode = (e) => {
    setBarCode(e.target.value);
  };

  const handleDetails = (e) => {
    setDetails(e.target.value);
  };

  const saveChanges = () => {
    let enabledSave = true;
    if (name !== '' && brand !== '' && barCode !== '' && productImages.length !== 0
    && subCategory.name !== 'Selecciona' && options.length !== 0) {
      // console.log(options);
      options.map((item) => {
        if (item.type !== '' && item.price !== '' && item.image !== '') {
          enabledSave = true;
        } else enabledSave = false;
      });

      if (enabledSave) {
        toggleModal();
      } else {
        setShowAlert(true);
      }
    } else {
      setShowAlert(true);
    }
  };

  const savetodb = async () => {
    const {
      unique, lowestPrice
    } = await mergeOptions(options, setOptions, category, subCategory, name);

    const url = await uploadProductImages();

    const prodInfo = {
      name,
      brand,
      images: url,
      categ: category,
      subcateg: subCategory,
      price: lowestPrice,
      barCode,
      details
    };

    const t = [...allProducts];
    const index = t.findIndex((item) => (item.id === ref));
    t[index] = { ...prodInfo, id: ref };

    setAllProducts(t);
    await updateAllProducts(t);
    await updateProductInfo(category.id, subCategory.id, ref, { brand, name, barCode });
    if (optionsId === '') {
      await addOptions(category.id, subCategory.id, ref, unique);
    } else {
      await updateOptions(category.id, subCategory.id, ref, optionsId, unique);
    }
    toggleModal();
    history.goBack();
  };

  const uploadProductImages = async () => {
    const tmp = [];
    await Promise.all(productImages.map(async (item) => {
      if (typeof item !== 'string') {
        const url = await UploadToStorage(`companies/companyName/category/${category.name}/${subCategory.name}/${name}`, item);
        tmp.push(url);
      } else tmp.push(item);
    }));

    return tmp;
  };

  return (
    <div className="d-flex container-fluid flex-column">
      <Title title="MODIFICAR PRODUCTO" buttonText="Guardar cambios" buttonAction={saveChanges} />
      <div className="d-flex container-fluid p-0 h-100">
        <SideNavBar />
        <Modal isOpen={showModal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Confirmar cambios</ModalHeader>
          <ModalBody>
            ¿Está seguro de que quiere guardar los cambios de este producto?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={savetodb}>Confimar</Button>{' '}
            <Button color="secondary" onClick={toggleModal}>Cancelar</Button>
          </ModalFooter>
        </Modal>
        <div className="container p-3 mt-4">
          <Alert color="danger" isOpen={showAlert} toggle={toggleAlert}>
            Por favor compruebe que todos los campos han sido rellenados.
          </Alert>
          <div className="row m-3">
            <span className="row col-12">
              1. Información general del producto
            </span>
            <div className="col-12 col-md-3 d-flex align-items-center mt-3 p-0">
              { productImages.length !== 0 ? (
                <ImageCarousel imagesArray={productImages} setImageArray={setProductImages} labelControl="addProductImgs" />
              )
                : (
                  <label htmlFor="addProductImgs" className="d-flex w-100 justify-content-center align-self-center">
                    <span className="d-flex justify-content-center align-items-center text-center py-2 border border-dark rounded-0" style={{ height: 150 }}>
                      Insertar imágenes
                    </span>
                  </label>
                )}
              <input className="d-none" type="file" id="addProductImgs" onChange={handleProductImages} />

            </div>
            <div className="col-12 col-md-9">
              <ul className="row my-4">
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Nombre del producto</b>
                    <input value={name} id="name" onChange={handleName} className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Marca del Producto</b>
                    <input value={brand} id="brand" onChange={handleBrand} className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
                <li className="col-12 col-sm-6">
                  <Dropdown title="Categoría" options={categories} setOption={selectCateg} selectedOption={category} disable={false} />
                </li>
                <li className="col-12 col-sm-6">
                  <Dropdown title="Subcategoria" options={subcategories} setOption={selectSubcateg} selectedOption={subCategory} disable={false} />
                </li>
                <li className="col-12 col-sm-6 ">
                  <div className="d-flex flex-column mx-lg-3">
                    <b>Código de barras</b>
                    <input value={barCode} id="reference" onChange={handleBarCode} className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
                <li className="col-12 col-sm-6 ">
                  <div className="d-flex flex-column mx-lg-3">
                    <span className="text-secondary"> <b className="text-dark">Detalles del producto</b> (Opcional)</span>
                    <textarea value={details} id="reference" onChange={handleDetails} rows="3" className="py-2 px-2 border border-secondary my-2 rounded-0" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row m-3">
            <span className="row col-12">
              2. Opciones de tamaño y color
            </span>
            <ul className="row container">
              {options === undefined ? <div /> : showOptions(options, setOptions, false)}

              <li className="my-5 col-12 col-md-6 d-flex align-items-center justify-content-center">
                <button type="button" disabled={isDisabled} onClick={() => handleAddOption(options, setOptions)} className="bg-white border-dark py-2 px-4 border rounded-5">
                  <GrAdd size={25} />
                  <span className="pl-3">Añadir opción de color y/o tamaño</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModifyProductScreen;
