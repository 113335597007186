import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import { FaLock } from 'react-icons/fa';
import { Alert } from 'reactstrap';
import Button from '../../components/login/btn';

import './styles/loginscreen.css';
import logo from '../../assets/logo_transparent.png';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState('');
  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const onDismiss = () => {
    setAlert(false);
  };

  const history = useHistory();

  function isDisable() {
    if (email === '' || password === '') {
      return true;
    }
    return false;
  }

  return (
    <div className="d-flex container-fluid justify-content-center align-items-center bg-img">
      <div className="d-flex flex-column justify-content-around align-items-center pt-2 col-11 col-sm-7 col-ml-5 col-lg-4 bg">
        <div className="container d-flex justify-content-center align-items-center">
          <img src={logo} alt="logo" className="img-fluid" style={{ height: 150 }} />
        </div>
        <div className="d-flex flex-column mt-1">
          <div className="d-flex flex-row pl-2 justify-content-center align-items-center border-bottom border-secondary">
            <div><MdMail size={19} /></div>
            <input
              type="email"
              className="pl-2 bg-transparent"
              style={{ border: 'none' }}
              name="userEmail"
              value={email}
              onChange={handleEmail}
              placeholder="Email"
              id="userEmail"
            />
          </div>
          <div className="d-flex flex-row pl-2 justify-content-center align-items-center border-bottom border-secondary mt-3">
            <div><FaLock size={18} /></div>
            <input
              type="password"
              className="pl-2 bg-transparent"
              style={{ border: 'none' }}
              name="userPwd"
              value={password}
              onChange={handlePassword}
              placeholder="Contraseña"
              id="userPwd"
            />
          </div>
        </div>
        <div className="mt-3">
          <Button text="ENTRAR" action="signin" email={email} password={password} history={history} isDisable={isDisable()} setAlert={setAlert} setError={setError} />
        </div>
        <div className="pt-5">
          <span className="text">He olvidado mi contraseña</span>
        </div>
      </div>
      { alert === true
        && (<div className="fixed-top"><Alert isOpen={alert} toggle={onDismiss} color="danger"> {error} </Alert></div>) }
    </div>
  );
};

export default LoginScreen;
