import React, { useState } from 'react';
import { BiSquare } from 'react-icons/bi';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import {
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { FaRegCaretSquareDown } from 'react-icons/fa';
import './styles/productListItem.css';

export const Filter = ({ header }) => (
  <>
    {header.canFilter ? header.render('Filter') : null}
  </>
);

export function SelectFilter({
  column: {
    setFilter, preFilteredRows, id, toggleSortBy
  }, tickbox, settickBox, selectAll, setselectAll
}) {
  const [drop, setdrop] = useState(false);
  let tmpSelect = [...tickbox];
  let tmpAll = selectAll;

  const filtering = React.useMemo(() => {
    const tmp = new Set();
    preFilteredRows.forEach((row) => {
      tmp.add(row.values[id]);
    });
    return [...tmp.values()];
  }, [id, preFilteredRows]);
  filtering.sort((a, b) => a.localeCompare(b));

  const toggleAll = () => {
    tmpAll = !tmpAll;
    setselectAll(tmpAll);
    if (tmpAll) {
      tmpSelect = [...filtering];
    } else {
      tmpSelect = [];
    }
    setFilter(undefined);
    settickBox(tmpSelect);
  };

  const handleSelect = (item) => {
    if (selectAll) {
      setselectAll(!selectAll);
    }
    const x = tmpSelect.findIndex((current) => current === item);
    if (x === -1) {
      tmpSelect.push(item);
    } else {
      tmpSelect.splice(x, 1);
    }
    console.log('ahah', tmpSelect);
    setFilter(tmpSelect);
    settickBox(tmpSelect);
  };

  const handleOrder = (orderType) => {
    if (orderType === 'Asc') {
      toggleSortBy(false, false);
    } else {
      toggleSortBy(true, false);
    }
  };

  return (
    <ButtonDropdown isOpen={drop}>
      <DropdownToggle color="light" className="ml-1 border-0 bg-transparent p-0">
        <FaRegCaretSquareDown size={20} color="black" onClick={() => setdrop(!drop)} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => handleOrder('Asc')}>Ordenar de A a Z</DropdownItem>
        <DropdownItem onClick={() => handleOrder('Des')}>Ordenar de Z a A</DropdownItem>
        <DropdownItem divider />
        <span className="pl-2">Filtar</span>
        <DropdownItem onClick={toggleAll}>
          {tmpAll ? <AiOutlineCheckSquare size={20} /> : <BiSquare size={20} />}
          <span className="ml-2">Seleccionar todo</span>
        </DropdownItem>
        {filtering.map((item) => (
          <DropdownItem onClick={() => handleSelect(item)}>
            {(tmpAll || tmpSelect.includes(item)) ? <AiOutlineCheckSquare size={20} /> : <BiSquare size={20} />}
            <span className="ml-2">{item}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
}
