/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { Collapse } from 'reactstrap';
import ProductListItem from './ProductListItem';
import './styles/orderList.css';

function convertMonth(month) {
  switch (month) {
    case '1':
      month = 'Enero';
      break;
    case '2':
      month = 'Febrero';
      break;
    case '3':
      month = 'Marzo';
      break;
    case '4':
      month = 'Abril';
      break;
    case '5':
      month = 'Mayo';
      break;
    case '6':
      month = 'Junio';
      break;
    case '7':
      month = 'Julio';
      break;
    case '8':
      month = 'Agosto';
      break;
    case '9':
      month = 'Septiembre';
      break;
    case '10':
      month = 'Octubre';
      break;
    case '11':
      month = 'Noviembre';
      break;
    case '12':
      month = 'Diciembre';
      break;
    default:
      break;
  }
  return month;
}

function items(products) {
  return (
    products.map((item, index) => (
      <ProductListItem
        image={item.image}
        price={item.price}
        quantity={item.quantity}
        index={index}
      />
    ))
  );
}

const OrderListItem = ({
  orderId, products, TotalPrice, date, address, allOpen, payment, index
}) => {
  const day = date.split('/')[0];
  const month = convertMonth(date.split('/')[1]);
  const year = date.split('/')[2];

  const [isOpen, setOpen] = useState(false);
  const [totalProducts, setTotalProducts] = useState(0);

  useEffect(() => {
    calculateProducts();
  }, []);

  const toggle = () => setOpen(!isOpen);

  const calculateProducts = () => {
    let total = 0;
    products.forEach((item) => {
      total += item.quantity;
    });
    setTotalProducts(total);
  };

  return (
    <li key={index} className="bg-white my-2">
      <button className="bg-white text-black p-1 col-12 px-sm-5" type="button" onClick={toggle}>
        <div className="d-flex row align-items-center p-1">
          <div className="col-7 col-md-4">
            <b>Pedido nº {orderId}</b>
          </div>
          {
            (isOpen === true || allOpen === true)
              ? '' : (<div className="col-4 col-md-2">{totalProducts} {totalProducts > 1 ? 'Productos' : 'Producto'}</div>)
          }
          {
            (isOpen === true || allOpen === true)
              ? '' : (<div className="col-3 col-md-2">{Number(TotalPrice).toFixed(2)} €</div>)
          }
          {
            (isOpen === true || allOpen === true)
              ? '' : (<div className="col-7 col-md-3">{day} {month} {year}</div>)
          }
          <div>
            {
              (isOpen === true || allOpen === true)
                ? <div className="col-1 col-md-1"><FiChevronDown size={30} /> </div> : <div className="col-1 col-md-1"><FiChevronRight size={30} /> </div>
            }
          </div>
        </div>
      </button>
      <Collapse
        isOpen={isOpen || allOpen}
      >
        <div className="d-flex align-items-center align-items-sm-start flex-column flex-sm-row my-2 pb-4 mr-sm-4">
          <div className="d-flex flex-column col-8 p-0">
            {/* <ul className="d-flex flex-row flex-ml-column align-items-center align-items-sm-start"> */}
            <ul className="contaner row sm-column align-items-center align-items-sm-start justify-content-center justify-content-sm-start">
              {items(products)}
            </ul>
          </div>
          <div className="col-10 col-sm-4 mr-sm-3 py-4 px-3 mt-4 mt-sm-0 greybg">
            <div className="p-1">Cantidad: {totalProducts}</div>
            <div className="p-1">Precio total: {parseFloat(TotalPrice).toFixed(2)} €</div>
            <div className="p-1">Fecha: {day} {month} {year}</div>
            <div className="p-1">Dirección de envio: {address}</div>
            <div className="p-1">Método de pago: {payment}</div>

          </div>
        </div>

      </Collapse>
    </li>
  );
};

export default OrderListItem;
