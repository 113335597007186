import React, { useState, useEffect } from 'react';
import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';
import { getOrders } from '../../firestore/admin';
import OrderListItem from '../../components/orders/OrderListItem';

const OrderScreen = () => {
  const [orders, setOrders] = useState([]);
  const [allOpen, setAllOpen] = useState(false);

  const toggle = () => setAllOpen(!allOpen);

  useEffect(() => {
    getAllOrders();
  }, []);

  async function getAllOrders() {
    const OrderAux = await getOrders();
    // OrderAux.sort((a, b) => new Date(b.date) - new Date(a.date));
    setOrders(OrderAux);
  }

  function items() {
    return (
      orders.map((item, index) => (
        <OrderListItem
          orderId={item.id}
          products={item.products}
          TotalPrice={item.total}
          date={item.date}
          address={item.deliveryAddr.address}
          payment={item.paymentMethod}
          allOpen={allOpen}
          index={index}
        />
      ))
    );
  }

  return (
    <div className="d-flex container-fluid flex-column">
      <Title title="PEDIDOS RECIBIDOS" />
      <div className="d-flex container-fluid p-0 h-100">
        <SideNavBar />
        <div className="container">
          <div className="d-flex justify-content-center align-items-center rounded mt-4">
            <button type="button" onClick={toggle} className="bg-white p-2 border border-secondary col-8 col-sm-6 col-md-4">
              <span><b>Ver detalles</b></span>
            </button>
          </div>
          <div className="container p-1 mt-4">
            <ul>
              {items()}
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};

export default OrderScreen;
