import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import SideNavBar from '../../components/navigation/SideNavBar';
import Title from '../../components/navigation/Title';
import { getAdmin, updateAdmin } from '../../firestore/admin';
import { signOut } from '../../firestore/authentication';

import './styles/profilescreen.css';

function ProfileScreen() {
  const [companyName, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [docid, setDocid] = useState();

  const history = useHistory();

  const handleCompanyName = (e) => setCompanyName(e.target.value);
  const handleAddress = (e) => setAddress(e.target.value);
  const handleName = (e) => setName(e.target.value);
  const handlePhone = (e) => setPhone(e.target.value);
  const handleEmail = (e) => setEmail(e.target.value);

  const handleSignOut = () => signOut(history);

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    const infoAux = await getAdmin();
    setCompanyName(infoAux.info.companyName);
    setAddress(infoAux.info.address);
    setName(infoAux.info.name);
    setPhone(infoAux.info.phone);
    setEmail(infoAux.info.email);
    setDocid(infoAux.id);
    // console.log(detail.Address);
  }

  const updateInfo = async () => {
    const detail = {
      companyName,
      address,
      name,
      phone,
      email,
    };

    await updateAdmin(docid, detail);
  };

  return (
    <div className="d-flex container-fluid flex-column">
      <Title title="PERFIL" buttonText="Guardar cambios" buttonAction={updateInfo} />
      <div className="d-flex container-fluid p-0 h-100">
        <SideNavBar />
        <div className="container p-1 mt-4">
          <div className="row m-3">
            <span className="row col-12">
              1. Establecimiento
            </span>
            <ul className="container row my-3 p-0">
              <li className="col-12 col-sm-6">
                <div className="d-flex flex-column mx-lg-3">
                  <b className="text-nowrap">Nombre del establecimiento</b>
                  <input className="py-2 px-2 border my-2 rounded-0" defaultValue={companyName} onChange={handleCompanyName} id="PropertyName" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="d-flex flex-column mx-lg-3">
                  <b>Dirección</b>
                  <input className="py-2 px-2 border my-2 rounded-0" defaultValue={address} onChange={handleAddress} id="PropertyAddress" />
                </div>
              </li>
            </ul>
          </div>
          <div className="row m-3">
            <span className="row col-12">
              2. Información personal
            </span>
            <ul className="container row my-3 p-0">
              <li className="col-12 col-sm-6">
                <div className="d-flex flex-column mx-lg-3">
                  <b>Nombre y Apellidos</b>
                  <input className="py-2 px-2 border my-2 rounded-0" defaultValue={name} onChange={handleName} id="CompleteName" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="d-flex flex-column mx-lg-3">
                  <b>Número de teléfono</b>
                  <input className="py-2 px-2 border my-2 rounded-0" defaultValue={phone} onChange={handlePhone} id="telephone" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="d-flex flex-column mx-lg-3">
                  <b>Email</b>
                  <input className="py-2 px-2 border my-2 rounded-0" defaultValue={email} onChange={handleEmail} id="email" />
                </div>
              </li>
            </ul>
          </div>
          <div className="row m-3">
            <span className="row col-12">
              3. Cambiar contraseña
            </span>
            <ul className="container row my-3 p-0">
              <li className="col-12 col-sm-6">
                <div className="d-flex flex-column mx-lg-3">
                  <b>Contraseña actual</b>
                  <input className="py-2 px-2 border my-2 rounded-0" defaultValue="" id="CurrentPassword" />
                </div>
              </li>
              <li className="col-12 col-sm-6">
                <div className="d-flex flex-column mx-lg-3">
                  <b>Nueva contraseña</b>
                  <input className="py-2 px-2 border my-2 rounded-0" defaultValue="" id="NewPassword" />
                </div>
              </li>
            </ul>
          </div>
          <div className="row flex-end mt-5 mb-3">
            <div className="d-flex flex-column align-items-center align-items-sm-start col-12 col-sm-6 pb-2 pl-0 pl-sm-4">
              <span className="mb-3"><b>¿Necesitas ayuda?</b></span>
              <span>Teléfono de contacto: 666666666</span>
              <span>Correo electrónico: bazar@info.com</span>
            </div>
            <div className="d-flex align-items-end justify-content-center justify-content-sm-end col-12 col-sm-6 pb-sm-2">
              <button className="px-5 py-2 rounded signout" type="button" onClick={handleSignOut}>
                <FiLogOut size={20} color="black" className="mr-3" />
                <span><b>Cerrar sesión</b></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileScreen;
