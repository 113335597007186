import React, { useState } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { FiChevronDown } from 'react-icons/fi';

const Dropdown = ({
  title, options, setOption, selectedOption
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const dropdownOptions = () => (
    options.map((item) => (
      <DropdownItem key={item.id} onClick={() => setOption(item)}>
        {item.name}
      </DropdownItem>
    ))
  );

  return (
    <div className="d-flex flex-column mx-lg-3">
      <b>{title}</b>
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle
          color="light"
          id="dropmenu"
          className="d-flex justify-content-between py-2 px-2 border border-secondary my-2 rounded-0"
          style={{ outline: 'none' }}
        >
          {selectedOption.name}
          <FiChevronDown size={25} />
        </DropdownToggle>
        <DropdownMenu className="w-100">
          {options === undefined ? <div /> : dropdownOptions()}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default Dropdown;
