import React, { useState } from 'react';
import {
  Navbar, NavbarBrand, NavbarToggler, Collapse, Nav
} from 'reactstrap';
import { AiOutlineBook, AiOutlineShop } from 'react-icons/ai';
import { MdFormatListBulleted } from 'react-icons/md';
import { FiUser } from 'react-icons/fi';
// import { GoGraph } from 'react-icons/go';

import './styles/title.css';
import { useLocation } from 'react-router-dom';
import homeLogoSm from '../../assets/homeLogo-sm.png';
import homeLogo from '../../assets/homeLogo.png';

function Title({ title, buttonText, buttonAction }) {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const toggleNavbar = () => setCollapsed(!collapsed);
  const categoriesActivate = (location.pathname.includes('/categories') ? ' element text-danger' : 'element');
  const productsActivate = (location.pathname.includes('/products') ? ' element text-danger' : 'element');
  const ordersActivate = (location.pathname.includes('/orders') ? ' element text-danger' : 'element');
  const profileActivate = (location.pathname.includes('/profile') ? ' element text-danger' : 'element');
  // const statisticsActivate = (location.pathname.includes('/statistics')
  // ? ' element text-danger' : 'element');
  return (
    <Navbar light color="white" sticky="top" className="px-0 shadow-sm">
      <div className="container-fluid d-flex flex-row m-0 align-items-center px-2">
        <NavbarBrand href="/categories" className="d-none d-sm-flex col-sm-4 py-0 mx-0">
          <img src={homeLogoSm} className="img-fluid" alt="homeLogo" style={{ height: 40 }} />
        </NavbarBrand>

        <div className="d-flex col-12 col-sm-4 justify-content-center">

          <NavbarBrand href="/categories" className="d-flex d-sm-none col-2 py-0 m-0 px-0">
            <img src={homeLogo} className="img-fluid" alt="homeLogo" style={{ height: 40 }} />
          </NavbarBrand>
          <NavbarBrand disabled className="m-0 col-8 col-sm-12 d-flex text-center justify-content-center navBarMargin">{title} </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2 d-flex d-sm-none col-2 justify-content-center" />
        </div>
        <Collapse isOpen={!collapsed} navbar>
          <Nav vertical className="align-top">
            {/* <li>
              <a href="/statistics" className="element">
                <GoGraph size={25} />
                <span className="px-3">Estadistícas</span>
              </a>
            </li> */}
            <li>
              <a href="/categories" className={categoriesActivate}>
                <MdFormatListBulleted size={25} />
                <span className="px-3">Categorías</span>
              </a>
            </li>
            <li>
              <a href="/products" className={productsActivate}>
                <AiOutlineShop size={25} />
                <span className="px-3">Productos</span>
              </a>
            </li>
            <li>
              <a href="/orders" className={ordersActivate}>
                <AiOutlineBook size={25} />
                <span className="px-3">Pedidos</span>
              </a>
            </li>
            <li>
              <a href="/profile" className={profileActivate}>
                <FiUser size={25} />
                <span className="px-3">Perfil</span>
              </a>
            </li>
          </Nav>

        </Collapse>

        { buttonText === undefined ? <div className="d-none d-sm-flex col-sm-4"> </div>
          : (
            <div className="d-flex col-12 col-sm-4 justify-content-center justify-content-sm-end align-items-center py-2" style={{ height: 35 }}>
              <button type="button" onClick={buttonAction} className="d-flex bg-red py-1 text-white align-self-center border justify-content-center mr-md-3" href="/">
                <span className="px-2">{buttonText}</span>
              </button>
            </div>
          ) }

      </div>
    </Navbar>
  );
}

export default Title;
