import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

console.log('config.js');

const config = {
  apiKey: 'AIzaSyB7SdY2uSYDFF4DGPXVGlwNqsomxLpCJp4',
  authDomain: 'retailapp-15b9e.firebaseapp.com',
  databaseURL: 'https://retailapp-15b9e.firebaseio.com',
  projectId: 'retailapp-15b9e',
  storageBucket: 'retailapp-15b9e.appspot.com',
  messagingSenderId: '467413108014',
  appId: '1:467413108014:web:b04690b499b61a1bd6e6bf',
  measurementId: 'G-L45M2ZF7TM'
};

firebase.initializeApp(config);

const db = firebase.firestore();
export const auth = firebase.auth();
export const storageRef = firebase.storage().ref();

export default db;
